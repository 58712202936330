'use client';

import { Stack, StackProps } from '@mui/material';

export type FormieRowContainerProps = StackProps<'div'>;

export default function FormieRowContainer({
  children,
  ...props
}: FormieRowContainerProps) {
  return (
    <Stack
      direction="row"
      alignItems="stretch"
      columnGap={2}
      rowGap={2}
      flexWrap={{ xs: 'wrap', md: 'nowrap' }}
      {...props}>
      {children}
    </Stack>
  );
}
