export const palette = {
  // 'blue': {
  //   400: '#001f6f',
  //   500: '#001f6f',
  // },
  // 'green': {
  //   600: '#001f6f',
  //   500: '#001f6f',
  // },
  // 'sky': {
  //   300: "var(--color-//)",
  //   200: '#DCF6FE',
  //   100: '#F1F8FA',
  // },
  'blue': {
    400: '#f00',
    500: '#f00',
  },
  'green': {
    600: '#f00',
    500: '#f00',
  },
  'sky': {
    300: '#f00',
    200: '#f00',
    100: '#f00',
  },
  'grey': {
    100: '#E6EAF2',
  },
  'midnight-blue': {
    200: '#F2F4FC',
    300: '#F8F9FD',
    500: '#001F6F',
  },
  'turquoise': { 400: '#26CAD3' },
  'white': '#FFFFFF',
  'black': '#000000',
  'inherit': 'inherit',
  'transparent': 'transparent',
};
