import { FragmentType } from '@/__generated__';
import { FieldFragment } from '@/__generated__/graphql';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { withFragmentArray } from '@liquorice/gql-utils';
import React, { Suspense } from 'react';
import { FORMIE_NESTED_ROW_FRAGMENT } from '../../gql/fragments/FORMIE_NESTED_ROW_FRAGMENT';
import { FormMethodsProps } from '../../lib/formTypes';
import FormieRowContainer from '../FormieRowContainer';

export type FormieFieldRowProps = React.PropsWithChildren<
  {
    parentHandle?: string;
  } & FormMethodsProps
>;

/**
 * In order to avoid circular dependencies, we need to use React.lazy to import FormieField.
 */
const FormieField = React.lazy(() => import('../FormieField'));

const FormieNestedRows = withFragmentArray(
  FORMIE_NESTED_ROW_FRAGMENT,
  (rows, { methods, parentHandle }: FormieFieldRowProps) => {
    if (!rows) return null;

    return (
      <Suspense fallback={<></>}>
        {rows.map((row, rowIndex) => {
          if (!row || !row.rowFields?.length) return null;

          return (
            <FormieRowContainer key={row.id ?? rowIndex} marginBottom={2}>
              {row.rowFields.map((field, i) => {
                // const f = getFragment

                const enabled = (field as Record<string, unknown>)?.enabled ?? false;

                if (!enabled) return null;

                return (
                  <FormieField
                    key={field?.id ?? i}
                    data={field as FragmentType<TypedDocumentNode<FieldFragment>>}
                    methods={methods}
                    parentHandle={parentHandle}
                  />
                );
              })}
            </FormieRowContainer>
          );
        })}
      </Suspense>
    );
  },
);

export default FormieNestedRows;
