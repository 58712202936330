'use client';

import { useElements, useStripe } from '@stripe/react-stripe-js';
import type { PaymentIntent } from '@stripe/stripe-js';
import React from 'react';
import type Stripe from 'stripe';
import { devError, devLog } from '../../../../lib/devLog';
import type { FormieSearchParams } from '../../../../types';
import { useResolvable, type ResolvableAction } from '../../../../utils/resolvable';
import type {
  Payment,
  PaymentActionVariables,
  PaymentConfirmVariables,
  PaymentResult,
  PaymentResultFailed,
  PaymentResultSuccess,
  UsePayment,
} from '../../types';
import { createPaymentIntent } from './stripeActions';

enum PaymentError {
  DEFAULT = 'There was an error processing your payment',
  NO_STRIPE = 'Stripe is not available',
  NO_ELEMENTS = 'Stripe Elements is not available',
  NO_INTENT = 'Payment intent is not available',
  NO_CLIENT = 'Client secret is not available',
  NO_CONFIRM = 'Payment confirmation failed',
  NO_SUBMIT = 'Payment submission failed',
}

export type StripePaymentIntent = Stripe.Response<Stripe.PaymentIntent> | PaymentIntent;

/**
 * Parses a Stripe payment intent object into a `StripePayment` object.
 *
 * @param data - The Stripe payment intent object to parse. Can be `null` or `undefined`.
 * @param complete - A flag indicating whether to include additional fields for completed payments.
 * @returns A `StripePayment` object if the input data is valid, otherwise `null`.
 */
function parseStripePayment(
  data?: StripePaymentIntent | null,
  complete?: boolean,
): StripePayment | null {
  if (!data) {
    return null;
  }

  const { id, amount, currency, status, client_secret } = data;

  // let customer;
  let customerId;
  if ('customer' in data) {
    // customer = data.customer;

    customerId = typeof data.customer === 'string' ? data.customer : data.customer?.id;
  }

  const payment = {
    reference: id,
    id,
    amount,
    currency,
    status,
    customerId,
    // customer,

    ...(complete
      ? {
          stripePaymentIntentId: id,
        }
      : {
          client_secret,
        }),
  } satisfies StripePayment;

  return payment;
}

export interface StripePayment extends Payment, Partial<Pick<Stripe.PaymentIntent, 'customer'>> {
  stripePaymentIntentId?: string | null;
  customerId?: string | null;
}

const useStripePayment: UsePayment<StripePaymentIntent, StripePayment> = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [result, setResult] = React.useState<PaymentResult<StripePaymentIntent> | null>(null);
  const [responseParams, setResponseParams] = React.useState<FormieSearchParams | null>(null);

  // const customerDetail = React.useMemo(() => {
  //   return {
  //     name: methods.getValues('billingName'),
  //     email: methods.getValues('billingEmail'),
  //     phone: methods.getValues('billingPhone'),
  //     address: methods.getValues('billingAddress'),
  //   }
  // }, [methods])

  const getPaymentResult = React.useCallback(
    async (params: FormieSearchParams): Promise<PaymentResult<StripePaymentIntent> | null> => {
      const paymentIntentId = params.payment_intent?.toString();
      const clientSecret = params?.payment_intent_client_secret?.toString();
      const redirectStatus = params?.redirect_status;

      devLog('params', params);

      if (!redirectStatus) return null;

      if (redirectStatus === 'succeeded' && paymentIntentId && clientSecret) {
        const payment = await stripe
          ?.retrievePaymentIntent(clientSecret)
          .then((result) => {
            devLog('result', result);
            return result?.paymentIntent ?? null;
          })
          .catch((error) => {
            devError('error', error);
            return null;
          });

        if (payment) {
          return {
            success: true,
            payment,
          } satisfies PaymentResultSuccess;
        }
      }
      return {
        success: false,
        error: 'Payment failed',
      } satisfies PaymentResultFailed;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stripe, elements],
  );

  React.useEffect(() => {
    if (!responseParams || !stripe || !elements) return;

    getPaymentResult(responseParams).then((value) => setResult(value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseParams, stripe, elements]);

  const validateFields = React.useCallback(async () => {
    if (elements == null) {
      throw new Error(PaymentError.NO_ELEMENTS);
    }

    if (stripe == null) {
      throw new Error(PaymentError.NO_STRIPE);
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();

    if (submitError) {
      throw new Error(submitError.message ?? PaymentError.NO_SUBMIT);
    }

    return true;
  }, [stripe, elements]);

  // const createCustomerAction = React.useCallback(
  //   async (content: Stripe.CustomerCreateParams) => {

  //     const name = methods.getValues('billingName');
  //     const email = methods.getValues('billingEmail');
  //     const phone = methods.getValues('billingPhone');
  //     const address = methods.getValues('billingAddress');

  //     return (await createCustomer(content))
  //   }
  // , []);

  const createPaymentAction: ResolvableAction<
    PaymentActionVariables,
    Stripe.Response<Stripe.PaymentIntent>
  > = React.useCallback(
    async (onSuccess, onError, options, metadata) => {
      if (elements == null) {
        return onError(PaymentError.NO_ELEMENTS);
      }

      if (stripe == null) {
        return onError(PaymentError.NO_STRIPE);
      }

      // Trigger form validation and wallet collection
      const { error: submitError } = await elements.submit();

      if (submitError) {
        // Show error to your customer
        return onError(submitError.message ?? PaymentError.NO_SUBMIT);
      }

      // Create the PaymentIntent and obtain clientSecret from your server endpoint

      try {
        const paymentIntentJson = await createPaymentIntent(options, metadata);
        const paymentIntent = JSON.parse(paymentIntentJson);

        if (!paymentIntent.client_secret) {
          return onError(PaymentError.NO_CLIENT);
        }

        return onSuccess(paymentIntent);
      } catch (error) {
        devError(error);
        return onError(PaymentError.DEFAULT);
      }
      // return onSuccess(paymentIntent);
    },
    [stripe, elements],
  );

  const paymentCreate = useResolvable(createPaymentAction, null);

  const confirmPaymentAction: ResolvableAction<PaymentConfirmVariables, true> = React.useCallback(
    async (onSuccess, onError, redirect) => {
      const currentUrl = window.location.href;

      const returnUrl = redirect ?? currentUrl;

      const paymentIntent = paymentCreate.isSuccess ? paymentCreate.value : null;

      const clientSecret = paymentIntent?.client_secret;

      if (elements == null || stripe == null || paymentIntent == null) {
        return onError(PaymentError.NO_INTENT);
      }

      // Trigger form validation and wallet collection
      const { error: submitError } = await elements.submit();

      if (submitError) {
        // Show error to your customer
        return onError(submitError.message ?? PaymentError.NO_SUBMIT);
      }

      if (!clientSecret) {
        return onError(PaymentError.NO_CLIENT);
      }

      const { error } = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        clientSecret,
        confirmParams: {
          return_url: returnUrl,
        },
      });

      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        return onError(error?.message ?? PaymentError.NO_CONFIRM);
      } else {
        return onSuccess(true);
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    },
    [paymentCreate.isSuccess, paymentCreate.value, elements, stripe],
  );

  const paymentConfirm = useResolvable(confirmPaymentAction, null);

  return {
    paymentCreate,
    paymentConfirm,
    parsePayment: parseStripePayment,
    validateFields,
    setResponseParams,
    result,
  };
};

export default useStripePayment;
