'use client';

import React from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export default function useRecaptchaExecute() {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [ready, setReady] = React.useState(false);
  const [token, setToken] = React.useState<string | null>(null);

  // Create an event handler so you can call the verification on button click event or form submit
  const requestToken = React.useCallback(
    async (name: string = 'form') => {
      setToken(null);

      // if (!USE_RECAPTCHA) return null;

      if (!executeRecaptcha) {
        console.warn('Execute recaptcha not yet available');
        return;
      }

      const token = await executeRecaptcha(name);

      setToken(token);

      return token;
    },
    [executeRecaptcha],
  );

  React.useEffect(() => {
    if (ready) return;
    setReady(!!executeRecaptcha);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executeRecaptcha]);

  return {
    enabled: true,
    ready: ready,
    token,
    requestToken,
  };
}
