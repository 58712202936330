import { palette } from '@/lib/utils/palette';
import { createTheme } from '@mui/material/styles';

const genuMainColours = {
  midnightBlue: palette['midnight-blue'][500],
  turquoise: palette['turquoise'][400],
  white: palette.white,
};

export const genuTheme = createTheme({
  cssVariables: {
    cssVarPrefix: '',
  },
  palette: {
    mode: 'light',
    primary: {
      main: genuMainColours.midnightBlue,
    },
    secondary: {
      main: genuMainColours.turquoise,
    },
    text: {
      primary: genuMainColours.midnightBlue,
    },
  },
  typography: {
    fontFamily: ['"roc-grotesk"', 'system-ui', 'sans-serif'].join(','),
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          'borderRadius': 999,
          'boxShadow': 'none',
          'textTransform': 'none',
          'color': genuMainColours.white,
          'fontSize': '1.125rem',
          'fontWeight': 500,
          'backgroundColor': genuMainColours.midnightBlue,
          '&:hover': {
            backgroundColor: genuMainColours.midnightBlue,
            boxShadow: 'none',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '--TextField-brandBorderColor': genuMainColours.midnightBlue,
          '--TextField-brandBorderHoverColor': genuMainColours.turquoise,
          '--TextField-brandBorderFocusedColor': genuMainColours.turquoise,
          'input': {
            color: genuMainColours.midnightBlue,
            borderColor: genuMainColours.midnightBlue,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderColor: genuMainColours.midnightBlue,
        },
        notchedOutline: {
          borderColor: genuMainColours.midnightBlue,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: genuMainColours.midnightBlue,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: genuMainColours.midnightBlue,
          fontSize: '0.875rem',
          lineHeight: '1.5rem',
          fontWeight: 500,
          margin: '0.5rem 0',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: genuMainColours.midnightBlue,
        },
      },
    },
  },
});
