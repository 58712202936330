import { FormieCaptchaFragment } from '@/__generated__/graphql';

const supportedKinds = ['recaptchaCaptcha'] as const;

export type FormieCaptchaType = (typeof supportedKinds)[number];

export type FormieCaptcha<T extends FormieCaptchaType = FormieCaptchaType> = {
  handle: T;
  name: string;
  value: string;
};

export type FormieCaptchas = {
  [P in FormieCaptchaType]?: FormieCaptcha<P>;
};

const parseFormieCaptchas = (captchas: FormieCaptchaFragment[]) => {
  return captchas.reduce((acc, captcha) => {
    const handle = captcha.handle ?? '';

    if (!supportedKinds.includes(handle as FormieCaptchaType)) return acc;

    return {
      ...acc,
      [handle]: {
        handle,
        name: captcha.name ?? '',
        value: captcha.value ?? '',
      },
    };
  }, {} as FormieCaptchas);
};

export default parseFormieCaptchas;
