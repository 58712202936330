'use client';

import { gql } from '@/__generated__';
import { Typography } from '@mui/material';
import { createField } from '../lib/form/createFieldControl';

export const FIELD_HEADING_FRAGMENT = gql(`
  fragment fieldHeading on Field_Heading {
    __typename
    ...fieldBase
  }
`);

export const Field_Heading = createField(FIELD_HEADING_FRAGMENT, (field, _props) => {
  return <Typography variant="h4">{field.label}</Typography>;
});
