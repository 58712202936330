import { VariantProps, cva } from 'class-variance-authority';
import { ReactNode } from 'react';

type SectionProps = VariantProps<typeof SectionStyles> & {
  children: ReactNode;
};

const SectionStyles = cva('', {
  variants: {
    color: {
      lightBlue: 'bg-sky-300 text-green-600',
      darkBlue: 'bg-blue-500 text-primary',
      darkGreen: 'bg-green-600 text-sky-300',
      // new colours
      white: 'bg-primary text-tBase',
      midnightBlue: 'bg-bgPrimary text-primary',
      turquoise: 'bg-accent text-tBase',
      tertiary: 'bg-tertiary text-tBase',
      primary: 'bg-primary text-tBase',
    },
    padding: {
      none: 'py-0',
      small: 'py-8',
      medium: 'py-12',
      large: 'py-12 lg:py-18 -mt-12 lg:-mt-18',
    },
  },
  defaultVariants: {
    color: 'white', // Default size if none is provided
    padding: 'large',
  },
});

export const Section = ({ color, children, padding, ...rest }: SectionProps) => {
  return (
    <div className={SectionStyles({ color, padding })} {...rest}>
      {children}
    </div>
  );
};
