import { isApolloError } from '@apollo/client';
import { FormErrors } from '../formTypes';

export default function parseFormSubmissionErrors(
  maybeError: unknown,
  ignoreErrors: string[] = [], // Allow for certain errors to be ignored
): FormErrors | null {
  let formErrors: FormErrors | null = null;
  try {
    const error = maybeError instanceof Error ? maybeError : null;

    if (!error) return null;

    if (isApolloError(error)) {
      const { graphQLErrors = [] } = error;

      formErrors = graphQLErrors.reduce((results, item) => {
        const { message } = item;

        try {
          const parsedMessage = JSON.parse(message) as FormErrors;

          const filteredErrors = Object.keys(parsedMessage).reduce((acc, key) => {
            if (ignoreErrors.includes(key)) {
              return acc;
            }
            return { ...acc, [key]: parsedMessage[key] };
          }, {});

          return {
            ...results,
            ...filteredErrors,
          };
        } catch {
          return {
            ...results,
            error: [message],
          };
        }
      }, {} as FormErrors);
    } else {
      console.warn('Caught Error', error?.message || maybeError);
      formErrors = { error: ['An error occurred.'] };
    }
  } catch (error) {
    console.error(error);
  }

  return formErrors;
}
