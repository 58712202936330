import { FragmentType, getFragmentData } from '@/__generated__';
import { FieldBaseFragment } from '@/__generated__/graphql';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { FormMethodsProps } from '../formTypes';
import parseFieldBase, { FieldBase } from '../parse/parseFieldBase';

/**
 * ExtendsFragment
 *
 * Create a type that extends a given fragment type.
 *
 */
export type ExtendsFragment<T> = {
  [' $fragmentRefs']?: {
    [key: string]: T;
  };
};

export type RecursiveExtendsFragmentArray<T extends unknown[]> = T extends [
  infer A,
  infer B,
  ...infer R,
]
  ? B extends ExtendsFragment<A>
    ? // Success - B extends A

      R extends unknown[]
      ? // More elements to compare
        RecursiveExtendsFragmentArray<[B, ...R]> extends never
        ? never
        : [A, B, ...R]
      : // No more elements to compare
        T
    : // Failure - B does not extend A
      never
  : // No more elements to compare
    T;
/*
function myFunction2<T extends unknown[]>(
  data: any,
  ...args: RecursiveExtendsFragmentArray<T>
) {
  return args;
}

const myResult1 = myFunction2({} as FieldBaseFragment, {} as FieldBaseFragment);

const myResult2 = myFunction2(
  {} as FieldBaseFragment,
  {} as FieldAgreeFragment,
); */

export type ExtendsFieldBaseFragment = ExtendsFragment<FieldBaseFragment>;

export type FieldData<T> = T extends ExtendsFieldBaseFragment ? FieldBase & T : FieldBase;

export type FieldProps<TType, P = unknown> = TType extends ExtendsFieldBaseFragment
  ? { field: FragmentType<TypedDocumentNode<TType>> } & P
  : { field: FieldBaseFragment } & P;

type FieldFunction<TType, P, R> = (props: FieldProps<TType, P>) => R;

export function createField<TType extends ExtendsFieldBaseFragment, P extends FormMethodsProps, R>(
  fragment: TypedDocumentNode<TType> | null,
  callback: (field: FieldData<TType>, props: P) => R,
): FieldFunction<TType, P, R> {
  const Field = ({ field: data, ...props }: FieldProps<TType, P>) => {
    const fieldRest = fragment ? getFragmentData(fragment, data) : {};
    const fieldBase = parseFieldBase(data);

    const fieldData = {
      ...fieldBase,
      ...fieldRest,
    } as FieldData<TType>;

    return callback(fieldData, props as unknown as P);
  };

  return Field;
}
