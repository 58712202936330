import React, { useState } from 'react';
import {
  type Rejected,
  type Resolution,
  type Resolvable,
  type Resolved,
  type Resolving,
} from './types';

export type UseResolvableReturn<
  V extends ResolvableActionVars,
  Y,
  N,
> = Resolution<Y, N> & {
  trigger: (...args: V) => Promise<Y | Error>;
  called: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ResolvableActionVars = any[];

export type ResolvableAction<V extends ResolvableActionVars, Y> = (
  resolve: (value: Y) => Y,
  reject: (err?: Error | null | string) => Error,
  ...args: V
) => Promise<Y | Error>;

export interface UseResolvableHook {
  <V extends ResolvableActionVars, Y, N>(
    action: ResolvableAction<V, Y>,
    fallback?: N,
  ): UseResolvableReturn<V, Y, N>;
}

const valueNotSet = Symbol('valueNotSet');

type ValueNotSet = typeof valueNotSet;

const useResolvable: UseResolvableHook = <V extends ResolvableActionVars, Y, N>(
  action: ResolvableAction<V, Y>,
  fallback: N = null as N,
): UseResolvableReturn<V, Y, N> => {
  const [isPending, setIsPending] = React.useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [error, setError] = useState<Error | string | null>(null);
  const [returnValue, setReturnValue] = useState<Y | ValueNotSet>(valueNotSet);
  const [called, setCalled] = useState<boolean>(false);

  const trigger = React.useCallback(
    async (...args: V): Promise<Y | Error> => {
      setCalled(true);
      setIsPending(true);

      const res = await action(
        (v) => {
          setReturnValue(v);
          setSuccess(true);
          return v;
        },
        (e) => {
          const error =
            e instanceof Error
              ? e
              : new Error(e ?? 'An unknown error occurred');
          setError(error);

          console.error(error);
          return error;
        },
        ...args,
      )
        .then((r) => {
          return r;
        })
        .catch((e) => {
          const error =
            e instanceof Error ? e : new Error('An unknown error occurred');
          setError(error);
          setSuccess(false);

          return error;
        })
        .finally(() => {
          setIsPending(false);
        });

      return res ?? new Error('An unknown error occurred');
    },
    [action],
  );

  const isError = !!error;

  const isSet = returnValue !== valueNotSet;
  const value = isSet ? returnValue : null;

  const resBase = {
    value,
    isSuccess,
    isPending,
    isError,
    error,
  } satisfies Resolvable<Y | N>;

  let res: Resolution<Y, N>;

  switch (true) {
    case resBase.isPending:
      res = {
        ...resBase,
        isPending: true,
        isSuccess: false,
        isError: false,
      } satisfies Resolving<Y | N>;
      break;

    case resBase.isSuccess && isSet:
      res = {
        ...resBase,
        error: null,
        isError: false,
        isPending: false,
        isSuccess: true,
      } satisfies Resolved<Y>;
      break;

    default:
      res = {
        ...resBase,
        value: fallback,
        isError: true,
        isPending: false,
        isSuccess: false,
      } satisfies Rejected<Y | N>;
  }

  return {
    ...res,
    trigger,
    called,
  };
};

export default useResolvable;
