import { gql } from '@apollo/client';
import type { TypedQueryDocumentNode } from 'graphql';
import { jsonToGraphQLQuery } from 'json-to-graphql-query';
import type { FieldValues } from 'react-hook-form';
import type { FormValues } from '../formTypes';

type GenericFormSubmissionMutationVariables = { [key: string]: never };

export interface GenericFormSubmissionFragment<T extends string = string> {
  __typename?: `${T}_Submission`;
  id?: string | null;
  uid?: string | null;
  isSpam?: boolean | null;
  spamReason?: string | null;
  isIncomplete?: boolean | null;
}

/**
 * A generic mutation just for typing
 */
export interface GenericFormSubmissionMutation<T extends string = string> {
  __typename?: 'Mutation';
  submission?: GenericFormSubmissionFragment<T> | null;
}

export type GenericFormSubmissionDocument = TypedQueryDocumentNode<
  GenericFormSubmissionMutation,
  GenericFormSubmissionMutationVariables
>;

export type FormSubmissionProps = {
  mutationName: string;
  submissionData: FormValues;
};

export type SubmissionValues = {
  [key: string]: string | number | boolean | null | SubmissionValues;
};

export const removeTypenameValues = (values: FieldValues) => {
  const newValues = { ...values };
  delete newValues.__typename;
  Object.keys(newValues).forEach((key) => {
    if (typeof newValues[key] === 'object') {
      newValues[key] = removeTypenameValues(newValues[key] as FieldValues);
    }
  });
  return newValues;
};

export default function createFormSubmissionMutation({
  mutationName,
  submissionData,
}: FormSubmissionProps) {
  submissionData = removeTypenameValues(submissionData);

  try {
    const graphql_query = jsonToGraphQLQuery(
      {
        mutation: {
          submission: {
            __aliasFor: mutationName,
            __args: submissionData,
            id: true,
            uid: true,
            isSpam: true,
            spamReason: true,
            isIncomplete: true,
          },
        },
      },
      { pretty: true },
    );

    return gql(graphql_query);
  } catch (error) {
    console.error('Error creating form submission mutation:', error, submissionData);
  }
  return null;
}

export type { GenericFormSubmissionMutation as FormSubmissionMutation };
