import { VariantProps, cva } from 'class-variance-authority';
import { ReactNode } from 'react';

type RoundedSectionProps = VariantProps<typeof RoundedSectionStyles> & {
  children: ReactNode;
  id?: string;
};

const RoundedSectionStyles = cva(
  'rounded-t-4xl lg:rounded-t-5xl', // Base styles
  {
    variants: {
      color: {
        darkBlue: 'bg-blue-500 text-primary',
        darkGreen: 'bg-green-600 text-sky-300',
        // new colours
        white: 'bg-primary text-tBase',
        midnightBlue: 'bg-bgPrimary text-primary',
        sky: 'bg-secondary text-tBase',
        turquoise: 'bg-accent text-tBase',
      },
      padding: {
        small: 'py-8',
        medium: 'py-12',
        large: 'py-12 lg:py-18',
      },
      noOverlap: {
        false: '-mt-16',
        true: '-mt-0 mb-16',
      },
    },
    defaultVariants: {
      color: 'white', // Default size if none is provided
      padding: 'large',
      noOverlap: false,
    },
  },
);

export const RoundedSection = ({
  color,
  children,
  id,
  padding,
  noOverlap = false,
  ...rest
}: RoundedSectionProps) => {
  return (
    <div id={id} className={RoundedSectionStyles({ color, padding, noOverlap })} {...rest}>
      {children}
    </div>
  );
};
