import { gql } from '@/__generated__';

export const FORMIE_NESTED_ROW_FRAGMENT = gql(`
  fragment formieNestedRow on RowInterface {
    __typename
    id
    rowFields {
      __typename
      id
      handle
      ...fieldBase
      ...fieldAddressCountry
    }
  }
`);
