import { gql } from '@/__generated__';

export const FORMIE_FORM_QUERY = gql(`
  query formieForm($id: [QueryArgument], $uid: [String]) {
    formieForm(id: $id, uid: $uid) {
      __typename
      id
      ...formieForm
    }
  }
`);
