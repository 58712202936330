'use client';

import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useFormie } from '../../lib/formieContext';
import useFormieSubmit from '../../lib/useFormieSubmit';
import FormieRows from '../FormieRows';
import FormieSubmitRow from '../FormieSubmitRow';
import { RecaptchaPolicy } from '../Recaptcha';

const FormieForm = () => {
  const methods = useFormContext();
  const form = useFormie((s) => s.form);

  const showGRecaptchaPolicy = useFormie((s) => s.showGRecaptchaPolicy);

  const { onSubmit } = useFormieSubmit();
  const setSubmitHandler = useFormie((s) => s.setSubmitHandler);
  const submitErrorHandler = useFormie((s) => s.submitErrorHandler);
  const hasPayment = useFormie((s) => s.payment?.hasPayment);

  React.useEffect(() => {
    setSubmitHandler(onSubmit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmit]);

  return (
    <form noValidate onSubmit={methods.handleSubmit(onSubmit, submitErrorHandler)}>
      <FormieRows data={form?.rows} methods={methods} />
      {hasPayment || <FormieSubmitRow />}
      <RecaptchaPolicy hide={!showGRecaptchaPolicy} marginTop={2} color="grey" variant="body2" />
    </form>
  );
};

export default FormieForm;
