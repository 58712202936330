'use client';

import { FragmentType, gql } from '@/__generated__';
import { FieldDropdownFragment } from '@/__generated__/graphql';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { createField } from '../lib/form/createFieldControl';
import { Field_Dropdown } from './Field_Dropdown';

export const FIELD_ADDRESS_COUNTRY_FRAGMENT = gql(`
  fragment fieldAddressCountry on Field_AddressCountry {
    __typename
    ...fieldBase
    options {
      ...fieldOption
    }
  }
`);
export const Field_AddressCountry = createField(FIELD_ADDRESS_COUNTRY_FRAGMENT, (field, props) => {
  return (
    <Field_Dropdown
      field={field as FragmentType<TypedDocumentNode<FieldDropdownFragment>>}
      {...props}
    />
  );
});
