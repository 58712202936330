import { UnknownProps } from '@liquorice/utils';
import React from 'react';
import ErrorBoundary from './ErrorBoundary';

export default function withErrorBoundary<P extends UnknownProps>(
  Component: React.ComponentType<P>,
) {
  return function WithErrorBoundary(props: P) {
    return (
      <ErrorBoundary>
        <Component {...props} />
      </ErrorBoundary>
    );
  };
}
