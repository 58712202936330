import { Elements } from '@stripe/react-stripe-js';
import type { StripeConstructorOptions } from '@stripe/stripe-js';
import { PaymentError } from '../../components/PaymentFeedback';
import type { PaymentFieldProviderProps } from '../../types';
import FormieStripeFields from './FormieStripeFields';
import useStripeLoader from './useStripeLoader';

export interface FormieStripeProviderProps
  extends PaymentFieldProviderProps<StripeConstructorOptions> {}

const FormieStripeProvider = (props: FormieStripeProviderProps) => {
  const { isDisabled, isError, error, isLoading, options, stripe } = useStripeLoader(props);

  if (isError) {
    return <PaymentError error={error} />;
  }

  if (isLoading || !stripe) {
    return null;
    // return <PaymentLoading />;
  }

  if (isDisabled) {
    return null;
    // return <PaymentLoading message="Waiting..." />;
  }

  return (
    <Elements stripe={stripe} options={options}>
      <FormieStripeFields amount={options.amount} currency={options.currency} />
    </Elements>
  );
};

export default FormieStripeProvider;
