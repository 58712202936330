import {
  Alert,
  Button,
  CircularProgress,
  Collapse,
  Stack,
  Typography,
  type ButtonProps,
} from '@mui/material';
import { useFormie } from '../../lib/formieContext';

export interface FormieSubmitRowProps {
  successMessage?: string | null;
  hideSuccessMessage?: boolean;
  forceSuccessMessage?: boolean;
  infoMessage?: string | null;
  hideInfoMessage?: boolean;
  errorMessage?: string | null;
  hideErrorMessage?: boolean;
  forceErrorMessage?: boolean;
  SubmitButtonProps?: ButtonProps;
  submitLabel?: string;
  error?: boolean;
}

const FormieSubmitRow = ({
  successMessage: _successMessage,
  errorMessage: _errorMessage,
  infoMessage: _infoMessage,
  hideSuccessMessage,
  hideInfoMessage,
  hideErrorMessage,
  forceSuccessMessage,
  forceErrorMessage,
  submitLabel = 'Submit',
  error,
  SubmitButtonProps,
}: FormieSubmitRowProps) => {
  const isLoading = useFormie((s) => s.isLoading);
  const isSubmitting = useFormie((s) => s.isSubmitting);
  // const isSubmitted = useFormie((s) => s.isSubmitted);
  const isSubmissionIncomplete = useFormie((s) => s.submission?.isIncomplete);
  const isSubmitError = useFormie((s) => s.isSubmitError);
  const isSuccess = useFormie((s) => s.isSubmitted);

  const { settings } = useFormie((s) => s.form);

  const successMessage = _successMessage ?? settings?.submitActionMessageHtml ?? '';
  const errorMessage = _errorMessage ?? settings?.errorMessageHtml ?? 'An error occurred';
  const infoMessage = _infoMessage ?? '';

  const isError = isSubmitError || error;

  const showLoading = isLoading || isSubmitting;

  const showInfo = !!infoMessage && !hideInfoMessage;

  const showErrors =
    (!!errorMessage && isError && !showLoading && !hideErrorMessage) || forceErrorMessage;

  const showSuccess =
    (!hideSuccessMessage &&
      !isSubmissionIncomplete &&
      !showErrors &&
      isSuccess &&
      !isLoading &&
      !!successMessage) ||
    forceSuccessMessage;

  const spacing = 4;

  return (
    <>
      <Collapse in={showErrors} unmountOnExit>
        <Alert severity="error" sx={{ mb: spacing }}>
          <Typography dangerouslySetInnerHTML={{ __html: errorMessage }} />
        </Alert>
      </Collapse>
      <Collapse in={showInfo} unmountOnExit>
        <Alert severity="info" sx={{ mb: spacing }}>
          <Typography dangerouslySetInnerHTML={{ __html: infoMessage }} />
        </Alert>
      </Collapse>
      <Collapse in={showSuccess} unmountOnExit>
        <Alert severity="success" sx={{ mb: spacing }}>
          <Typography dangerouslySetInnerHTML={{ __html: successMessage }} />
        </Alert>
      </Collapse>
      <Stack flexDirection="row" justifyContent="space-between">
        <Button
          type="submit"
          size="large"
          disabled={showLoading}
          variant="contained"
          {...SubmitButtonProps}>
          {SubmitButtonProps?.children ?? submitLabel}
        </Button>

        {showLoading && <CircularProgress color="inherit" size={40} />}
      </Stack>
    </>
  );
};

export default FormieSubmitRow;
