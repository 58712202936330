/* eslint-disable @typescript-eslint/no-explicit-any */
import type { FieldFragment, FormieFormFragment } from '@/__generated__/graphql';
import { gql } from '@apollo/client';
import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { jsonToGraphQLQuery } from 'json-to-graphql-query';
import type { GenericFormSubmissionFragment } from './createFormSubmissionMutation';

export type FieldList = {
  [key: string]: boolean | FieldList;
};

export const createFieldList = (
  // data?: FormieRowFragment | (<FieldFragment | NestedFieldFragment>[]) | null
  data?: any,
): FieldList => {
  // console.log('data', data);

  if (!data) return {};

  // Handle the case where the data is an array of fields
  if (Array.isArray(data)) {
    return data.reduce((acc, field) => {
      if (!field.handle) return acc;

      if (!field.enabled) return acc;

      if ('nestedRows' in field) {
        acc[field.handle] =
          field?.nestedRows?.reduce((acc: any, nestedRow: any) => {
            const nestedFields = createFieldList(nestedRow) as FieldList;

            return { ...acc, ...nestedFields };
          }, {} as FieldList) || {};
      } else {
        acc[field.handle] = true;
      }
      return acc;
    }, {} as FieldList);
  }

  // Handle the case where the data is a single row
  if ('rowFields' in data) {
    return createFieldList((data as any).rowFields as unknown as FieldFragment[]);
  }

  // Handle the case where the data is a form
  if ('rows' in data) {
    return (data?.rows as any[]).reduce((acc, row) => {
      const fieldList = createFieldList(row);
      return { ...acc, ...fieldList };
    }, {} as FieldList);
  }

  return {};
};

export interface FormSubmissionQueryVariables {
  uid: string;
  form: string;
  isIncomplete?: boolean;
  isSpam?: boolean;
}

export interface FormSubmissionQuery {
  formieSubmission?: GenericFormSubmissionFragment | null;
}

export default function createFormSubmissionQuery(
  form: FormieFormFragment,
  variables: FormSubmissionQueryVariables,
) {
  try {
    const formHandle = form.handle || '';

    const fields = createFieldList(form);

    if (!fields) return null;

    const graphql_query = jsonToGraphQLQuery(
      {
        query: {
          formieSubmission: {
            __args: variables,
            id: true,
            uid: true,
            isSpam: true,
            spamReason: true,
            isIncomplete: true,
            __on: {
              __typeName: `${formHandle}_Submission`,
              ...fields,
            },
          },
        },
      },
      { pretty: true },
    );

    return gql(graphql_query) as TypedDocumentNode<FormSubmissionQuery>;
  } catch (error) {
    console.error('Error creating form submission query:', error);
  }
  return null;
}
