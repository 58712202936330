import { gql } from '@/__generated__';

export const FORMIE_FORM_FRAGMENT = gql(`
  fragment formieForm on FormInterface {
    __typename
    id
    uid
    handle
    submissionMutationName
    slug
    rows {
      ...formieRow
    }
    captchas {
      ...formieCaptcha
    }
    settings {
      submitActionMessageHtml
      errorMessageHtml
    }
    paymentFields: formFields {
      ...fieldPayment
    }
  }
`);
