import { gql } from '@/__generated__';

export const FIELD_OPTION_FRAGMENT = gql(`
  fragment fieldOption on FieldOption {
    __typename
    value
    label
    isDefault
  }
`);
