'use client';

import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { createFormieStore, FormieContext, type FormieContextValue } from '../../lib/formieContext';
import type { FormValues } from '../../lib/formTypes';
import { type FormieState } from '../../types';

export interface FormieProviderProps {
  initialState: FormieState;
  children: React.ReactNode;
}

const FormieProvider = ({ initialState, children }: FormieProviderProps) => {
  const storeRef = React.useRef<FormieContextValue>();

  const { defaultValues, retrievedValues } = initialState;

  const methods = useForm<FormValues>({
    mode: 'onChange',
    defaultValues,
  });

  React.useEffect(() => {
    methods.clearErrors();
    if (retrievedValues) {
      methods.reset(retrievedValues, {
        keepDefaultValues: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retrievedValues]);

  if (!storeRef.current) {
    storeRef.current = createFormieStore(initialState);
  }

  return (
    <FormieContext.Provider value={storeRef.current}>
      <FormProvider {...methods}>{children}</FormProvider>
    </FormieContext.Provider>
  );
};

export default FormieProvider;
