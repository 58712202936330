'use client';

import { withFragment } from '@liquorice/gql-utils';
import { Controller } from 'react-hook-form';
import { FIELD_BASE_FRAGMENT } from '../gql/fragments/FIELD_BASE_FRAGMENT';
import { FormMethodsProps } from '../lib/formTypes';
import parseFieldBase from '../lib/parse/parseFieldBase';
import {
  DefaultTextField,
  DefaultTextFieldProps,
} from './DefaultTextField/DefaultTextField';
import FieldErrors from './FieldErrors';

type Field_TextProps = FormMethodsProps;

export const Field_Text = withFragment(
  FIELD_BASE_FRAGMENT,
  (data, { methods }: Field_TextProps) => {
    const field = parseFieldBase(data);

    if (!field) return null;

    const {
      defaultValue,
      handle,
      label,
      placeholder,
      required,
      instructions,
      disabled,
    } = field;

    const fieldProps: DefaultTextFieldProps = {
      // error: !!error,
      disabled,
      helperText: instructions,
      label,
      placeholder,
      required,
    };

    switch (field.__typename) {
      case 'Field_MultiLineText':
        fieldProps.multiline = true;
        fieldProps.minRows = 3;
        break;

      case 'Field_Email':
        fieldProps.type = 'email';
        break;

      case 'Field_Number':
        fieldProps.type = 'number';
        break;

      case 'Field_Phone':
        fieldProps.type = 'tel';
        break;

      case 'Field_Hidden':
        fieldProps.type = 'hidden';
        fieldProps.hidden = true;
        break;
    }

    return (
      <Controller
        name={handle}
        control={methods.control}
        defaultValue={defaultValue ?? ''}
        render={({ field: { ref, ...rest }, fieldState: { error } }) => (
          <>
            <DefaultTextField
              {...rest}
              error={!!error}
              {...fieldProps}
              inputProps={{ ref }}
            />
            <FieldErrors errors={error} />
          </>
        )}
      />
    );
  },
);
