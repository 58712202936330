'use client';

import React from 'react';
import { createStore, StoreApi, UseBoundStore, useStore } from 'zustand';
import type { FormieState, FormieStore } from '../types';
import { devLog } from './devLog';
import { parseSubmissionMeta } from './isSubmissionMeta';

export type FormieContextValue = StoreApi<FormieStore>;

export const FormieContext = React.createContext<FormieContextValue | null>(null);

export const formieDefaultState: Omit<FormieState, 'form' | 'formHandle'> = {
  searchParams: {},
  submission: null,
  payment: null,
  validateOnly: false,
  ignoreErrors: [],
  isLoading: false,
  isSubmitting: false,
  isSubmitted: false,
  isSubmitError: false,
  submitHandler: console.log,
  submitErrorHandler: console.error,
};

export const createFormieStore = (initialState: FormieState) => {
  return createStore<FormieStore>((set, get) => ({
    ...initialState,
    setSubmitHandler: (submitHandler, submitErrorHandler) =>
      set({ submitHandler, submitErrorHandler }),
    setIsLoading: (isLoading) => set({ isLoading }),
    setIsSubmitting: () =>
      set({
        isSubmitting: true,
        isSubmitted: false,
        isSubmitError: false,
        error: null,
      }),
    setIsSubmitError: (error) => {
      // console.error(error);
      set({
        isSubmitError: true,
        isSubmitting: false,
        isSubmitted: false,
        error,
        // validateOnly: false, // Reset on each submission
        // ignoreErrors: [], // Reset on each submission
      });
    },
    setIsSubmitted: (isSubmitted, data) => {
      set({
        isSubmitted,
        submission: parseSubmissionMeta(data),
        isSubmitting: false,
        isSubmitError: false,
        error: null,
        // validateOnly: false, // Reset on each submission
        // ignoreErrors: [], // Reset on each submission
      });
    },
    setValidateOnly: (value = true) => {
      set({ validateOnly: value });
      return true;
    },
    setIgnoreErrors: (errors) => {
      devLog('setIgnoreErrors', errors);
      set({ ignoreErrors: errors });
    },
    setPayment: (payment) => {
      const prevPaymentState = get().payment;

      if (!prevPaymentState) return;

      const prevId = prevPaymentState.payment?.id;
      const newId = payment?.id;

      if (prevId === newId) {
        console.log('setPayment: same payment');
        return;
      }

      set({ payment: { ...prevPaymentState, payment } });
    },
    getPayment: () => {
      const { payment } = initialState;

      return payment?.payment || null;
    },
    getState: () => {
      const state = get();

      return Object.keys(initialState).reduce((acc, key) => {
        return { ...acc, [key]: state[key as keyof FormieState] };
      }, {} as FormieState);
    },
  }));
};

export const useFormie = ((selector) => {
  const store = React.useContext(FormieContext);

  if (!store) {
    throw new Error('useFormie must be used within a FormieProvider');
  }

  return useStore(store, selector);
}) as UseBoundStore<StoreApi<FormieStore>>;
