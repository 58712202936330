import type { HandlesPayment } from './paymentTypes';

export enum PaymentProviderTypeEnum {
  stripe = 'stripe',
  // paypal = 'paypal',
}

export type PaymentProviderType = `${PaymentProviderTypeEnum}`;

export type Amount = number | string;

export interface VariablePaymentSettings {
  amountField?: string;
  currencyField?: string;
}

export interface FixedPaymentSettings {
  amount?: Amount;
  currency?: string;
}

export interface PaymentSettings extends VariablePaymentSettings, FixedPaymentSettings {}

export interface AmountSettings {
  amount: number;
  amountFormatted?: string;
  currency?: string;
}

export enum PaymentProviderSettingEnum {
  fixed = 'fixed',
  dynamic = 'dynamic',
}

export type PaymentProviderSettingType = `${PaymentProviderSettingEnum}`;

export interface PaymentProviderSettings extends PaymentSettings {
  type: string;
  fieldHandle: string;
  amountType: PaymentProviderSettingType;
  currencyType: PaymentProviderSettingType;
  metadata?: unknown[];
}

export interface PaymentFieldProviderProps<T> extends AmountSettings, HandlesPayment {
  disabled?: boolean;
  loading?: boolean;
  options?: T;
  publicKey?: string;
}
