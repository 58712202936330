'use client';

import { ImageAssetFragment } from '@/__generated__/graphql';
import ImageAsset, { MaybeImage } from '../Atoms/ImageAsset';

export interface CardIconImageProps {
  icon: ImageAssetFragment | MaybeImage;
}

const CardIconImage = ({ icon }: CardIconImageProps) => {
  return (
    <div className="mx-6 mt-6 flex h-[62px] w-[62px] items-center justify-center md:mx-8 md:mt-8">
      <ImageAsset data={icon} height={62} />
    </div>
  );
};

export default CardIconImage;
