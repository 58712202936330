import { ImageAssetFragment } from '@/__generated__/graphql';
import ImageAsset, { MaybeImage } from '../Atoms/ImageAsset';

const CardImage = ({ image }: { image: ImageAssetFragment | MaybeImage }) => (
  <div className="bg-accent aspect-h-2 aspect-w-3 w-full">
    {image && <ImageAsset data={image} className="object-cover object-center" />}
  </div>
);

export default CardImage;
