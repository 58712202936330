'use client';

import { FormieFormQueryVariables } from '@/__generated__/graphql';
import { Suspense } from 'react';
import Form, { FormComponentOptions } from '../Form/Form';
import useFormieQuery from '../lib/useFormieQuery';

export interface FormLoaderProps extends FormComponentOptions {
  queryVariables: FormieFormQueryVariables;
}

export default function FormLoader({ queryVariables = {}, ...options }: FormLoaderProps) {
  const formData = useFormieQuery(queryVariables);
  return (
    <Suspense fallback={options.loadingFallback}>
      <Form {...options} {...formData} />
    </Suspense>
  );
}
