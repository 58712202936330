import {
  loadStripe,
  Stripe,
  StripeConstructorOptions,
  StripeElementsOptionsMode,
} from '@stripe/stripe-js';
import React, { useState } from 'react';
import type { AmountSettings, PaymentFieldProviderProps } from '../../types';
import { getPaymentConstants } from '../../utils/paymentUtils';

export interface StripeLoaderPendingReturn {
  stripe: Stripe | null;
  error?: Error | undefined;
  isError: boolean;
  isDisabled: boolean;
  isLoading: true;
  options: StripeElementsOptionsMode;
}
export interface StripeLoaderResolvedReturn {
  stripe: Stripe;
  error?: undefined;
  isError: false;
  isDisabled: false;
  isLoading: false;
  options: StripeElementsOptionsMode & AmountSettings;
}

export type StripeLoaderReturn = StripeLoaderPendingReturn | StripeLoaderResolvedReturn;

export default function useStripeLoader(
  props: PaymentFieldProviderProps<StripeConstructorOptions>,
): StripeLoaderReturn {
  const { STRIPE_CURRENCY_DEFAULT, STRIPE_PUBLISHABLE_KEY } = getPaymentConstants();

  const {
    disabled,
    loading,
    options: constructorOptions,
    currency,
    amount: amountProp,
    publicKey = STRIPE_PUBLISHABLE_KEY,
  } = props;

  const options: StripeElementsOptionsMode = {
    mode: 'payment',
    amount: amountProp,
    currency: (currency || STRIPE_CURRENCY_DEFAULT).toLowerCase(),
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };

  const [error, setError] = useState<Error>();

  const [isStripeLoading, setIsStripeLoading] = useState(true);

  const stripeRef = React.useRef<Stripe | null>(null);

  React.useEffect(() => {
    if (!publicKey) {
      setError(new Error('FormieStripe: Missing publicKey'));
      return;
    }

    loadStripe(publicKey, constructorOptions)
      .then((stripe) => {
        if (!stripe) {
          setError(new Error('FormieStripe: Failed to load Stripe'));
        } else {
          stripeRef.current = stripe;
        }
        setIsStripeLoading(false);
        return stripe;
      })
      .catch((error) => {
        setError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKey]);

  let result: StripeLoaderReturn;

  const stripe = stripeRef.current;
  const amount = options.amount;

  const isDisabled = !!disabled;
  const isLoading = isStripeLoading || loading;
  const isError = !!error;

  const isResolved = stripe && amount && !isLoading && !isDisabled && !isError;

  if (isResolved) {
    result = {
      stripe,
      error,
      isError,
      isDisabled,
      isLoading: false,
      options: { ...options, amount },
    };
  } else {
    result = {
      stripe,
      error,
      isError,
      isDisabled,
      isLoading: true,
      options: options,
    };
  }

  return result;
}
