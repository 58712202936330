'use client';

import { ImageAssetFragment } from '@/__generated__/graphql';
import { Button } from '@/components/Atoms/Button';
import { RightArrow } from '@/components/Atoms/Icons';
import LeftArrow from '@/components/Atoms/Icons/LeftArrow';
import ImageAsset from '@/components/Atoms/ImageAsset';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

export interface FullscreenScrollingImageBlockProps {
  openFullscreen?: boolean;
  CloseFullscreen?: () => void;
  showFloorplan?: boolean;
  setShowFloorplan: Dispatch<SetStateAction<boolean>>;
  images: ImageAssetFragment[];
}

export const FullscreenScrollingImageBlock = ({
  images,
  openFullscreen,
  CloseFullscreen,
  setShowFloorplan,
  showFloorplan,
}: FullscreenScrollingImageBlockProps) => {
  const slideshowRef = useRef<HTMLUListElement>(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToNextSlide = () => {
    if (slideshowRef.current) {
      const nextIndex = (currentIndex + 1) % images.length;
      slideshowRef.current.scrollLeft = slideshowRef.current.clientWidth * nextIndex;
    }
  };

  const goToPreviousSlide = () => {
    if (slideshowRef.current) {
      const previousIndex = (currentIndex - 1 + images.length) % images.length;
      slideshowRef.current.scrollLeft = slideshowRef.current.clientWidth * previousIndex;
    }
  };
  // if showFloorplan is true, scroll to the end of the slideshow and restart the setShowFloorplan
  useEffect(() => {
    if (showFloorplan) {
      setShowFloorplan(false);
      if (slideshowRef.current) {
        slideshowRef.current.scrollLeft = slideshowRef.current.clientWidth * (images.length - 1);
      }
    }
  }, [images, setShowFloorplan, showFloorplan]);

  // add an event listener to the slideshowRef to update the currentIndex when the user scrolls
  useEffect(() => {
    if (slideshowRef.current != undefined) {
      const currentRef = slideshowRef.current;
      const handleScroll = () => {
        const index = Math.round(currentRef.scrollLeft / currentRef.clientWidth);
        setCurrentIndex(index);
      };
      currentRef.addEventListener('scroll', handleScroll);
      return () => {
        currentRef.removeEventListener('scroll', handleScroll);
      };
    }
  });

  return (
    <div
      className={`${openFullscreen ? 'fixed' : 'hidden'} bg-bgPrimary left-0 top-0 h-full w-full bg-opacity-85`}>
      <ul
        ref={slideshowRef}
        className={`no-scrollbar flex h-full w-[calc(100vw*${images.length})] snap-x snap-mandatory overflow-x-scroll ${!showFloorplan ? 'scroll-smooth' : ''}`}>
        {images.map((image, index) => (
          <li key={index} className={`w-full flex-shrink-0 snap-center snap-always`}>
            <ImageAsset data={image} className="h-full w-full object-contain" />
          </li>
        ))}
      </ul>

      <div className="absolute right-4 top-4">
        <Button
          aria-label="Exit fullscreen"
          element="button"
          size="circleResponsive"
          color="white"
          onClick={CloseFullscreen}>
          {/* insert svg with a close "X" */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-[1.5em] w-[1.5em]"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={0.875}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </Button>
      </div>
      <div className="absolute left-4 top-1/2 -translate-y-1/2 transform">
        <Button
          aria-label="Previous image"
          element="button"
          size="circleResponsive"
          color="white"
          onClick={goToPreviousSlide}>
          <LeftArrow className="stroke-bgPrimary" />
        </Button>
      </div>
      <div className="absolute right-4 top-1/2 -translate-y-1/2 transform">
        <Button
          aria-label="Next image"
          element="button"
          size="circleResponsive"
          color="white"
          onClick={goToNextSlide}>
          <RightArrow className="stroke-bgPrimary" />
        </Button>
      </div>
    </div>
  );
};
