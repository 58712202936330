'use client';

import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

type RecaptchaProviderProps = React.PropsWithChildren<{
  reCaptchaKey?: string;
  hideBadge?: boolean;
}>;

export default function RecaptchaProvider({
  reCaptchaKey,
  hideBadge,
  children,
}: RecaptchaProviderProps) {
  return reCaptchaKey ? (
    <GoogleReCaptchaProvider
      reCaptchaKey={reCaptchaKey}
      // useEnterprise
      scriptProps={{
        async: false, // optional, default to false,
        defer: true, // optional, default to false
        appendTo: 'body', // optional, default to "head", can be "head" or "body",
        nonce: undefined,
      }}
      container={{
        parameters: {
          badge: 'inline',
        },
      }}>
      {children}
      {hideBadge && (
        <style>{`.grecaptcha-badge { visibility: hidden; }`}</style>
      )}
    </GoogleReCaptchaProvider>
  ) : (
    <>{children}</>
  );
}
