'use client';

import { gql } from '@/__generated__';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useController } from 'react-hook-form';
import { createField } from '../lib/form/createFieldControl';
import useFieldOptions from '../lib/parse/useFieldOptions';
import FieldErrors from './FieldErrors';

export const FIELD_RADIO_FRAGMENT = gql(`
  fragment fieldRadio on Field_Radio {
    __typename
    ...fieldBase
    layout
    options {
      ...fieldOption
    }
  }
`);

export const Field_Radio = createField(FIELD_RADIO_FRAGMENT, (field, { methods }) => {
  const { label, handle, required, options: _options, instructions, layout, labelPosition } = field;

  const { defaultValue, options } = useFieldOptions(_options);

  const {
    field: fieldControl,
    fieldState: { error },
  } = useController({
    name: handle,
    control: methods.control,
    defaultValue: defaultValue ?? '',
  });

  return (
    <FormControl required={required} error={!!error}>
      <FormLabel component="legend" sx={{ mb: 1 }} hidden={labelPosition === 'hidden'}>
        {label}
      </FormLabel>
      <FieldErrors errors={error} />
      <FormHelperText>{instructions}</FormHelperText>
      <RadioGroup
        {...{
          ...fieldControl,
          row: layout === 'horizontal',
          sx: { mb: 1 },
        }}>
        {options.map((v) => (
          <FormControlLabel key={v.value} value={v.value} control={<Radio />} label={v.label} />
        ))}
      </RadioGroup>
    </FormControl>
  );
});
