/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Maybe, PartialRequired } from '@liquorice/utils';
import type { FieldValues } from 'react-hook-form';
import type { FormieSubmissionMeta } from '../types';
import type { GenericFormSubmissionFragment } from './submission/createFormSubmissionMutation';

export type FormieSubmissionBaseMeta = PartialRequired<FormieSubmissionMeta, 'id' | 'uid'>;

export const isSubmission = <T extends GenericFormSubmissionFragment>(
  data: Maybe<T>,
): data is FormieSubmissionBaseMeta & T => {
  if (!data) return false;

  if (typeof data !== 'object') return false;

  if (!('id' in data)) return false;

  if (!('uid' in data)) return false;

  return true;
};

export function stripNullishValues<T extends Record<string, any>>(
  data: T,
  stripKeys: string[] = [],
): PartialRequired<T, keyof T> {
  const result = Object.keys(data).reduce(
    (acc, key) => {
      if (stripKeys.includes(key)) return acc;

      let keyData = data[key];
      if (keyData === null || keyData === undefined) return acc;

      if (typeof keyData === 'object') {
        keyData = stripNullishValues(keyData, stripKeys);
        if (Object.keys(keyData).length === 0) return acc;
      }

      return { ...acc, [key]: keyData };
    },
    {} as PartialRequired<T, keyof T>,
  );

  return result;
}

export function parseSubmissionMeta<T extends GenericFormSubmissionFragment>(
  data: T,
): FormieSubmissionMeta;
export function parseSubmissionMeta<T extends GenericFormSubmissionFragment>(
  data: Maybe<T>,
): FormieSubmissionMeta | null {
  if (!isSubmission(data)) return null;

  return {
    id: data.id,
    uid: data.uid,
    isIncomplete: !!data.isIncomplete,
    isSpam: !!data.isSpam,
    spamReason: data.spamReason || null,
  } satisfies FormieSubmissionMeta;
}

export const parseSubmission = <T extends GenericFormSubmissionFragment>(
  data: Maybe<T>,
): {
  values: FieldValues;
  meta: FormieSubmissionMeta;
} | null => {
  if (!isSubmission(data)) return null;

  const values = stripNullishValues(data, ['__typename']);

  const meta = parseSubmissionMeta(values);
  const metaKeys = Object.keys(meta) as (keyof FormieSubmissionMeta)[];

  metaKeys.forEach((key) => {
    delete values[key];
  });

  // devLog('parseSubmission', data, values, meta);

  return {
    values,
    meta,
  };
};
