'use client';

import { Typography, TypographyProps } from '@mui/material';

export interface RecaptchaPolicyProps extends TypographyProps {
  hide?: boolean;
}

const RecaptchaPolicy = ({ hide, ...props }: RecaptchaPolicyProps) => {
  if (hide) return null;

  return (
    <Typography
      {...props}
      dangerouslySetInnerHTML={{
        __html: `This site is protected by reCAPTCHA and the Google
      <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">Privacy Policy</a> and
      <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer">Terms of service</a>
      apply.`,
      }}
    />
  );
};
export default RecaptchaPolicy;
