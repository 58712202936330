'use client';

import { FragmentType, gql } from '@/__generated__';
import { FieldBaseFragment } from '@/__generated__/graphql';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { FormControl, FormHelperText, Typography } from '@mui/material';
import { createField } from '../lib/form/createFieldControl';
import { Field_Text } from './Field_Text';
import { FormieNestedRows } from './FormieNestedRows';

export const FIELD_NAME_FRAGMENT = gql(`
  fragment fieldName on Field_Name {
    __typename
    ...fieldBase
    useMultipleFields
    nestedRows {
      ...formieNestedRow
    }
  }
`);

export const Field_Name = createField(FIELD_NAME_FRAGMENT, (field, props) => {
  const { useMultipleFields } = field;

  if (useMultipleFields) {
    const { nestedRows, handle, label, instructions, labelPosition } = field;
    return (
      <FormControl fullWidth>
        <Typography component="legend" sx={{ mb: 2 }} hidden={labelPosition === 'hidden'}>
          {label}
        </Typography>

        {instructions && <FormHelperText>{instructions}</FormHelperText>}

        <FormieNestedRows data={nestedRows} parentHandle={handle} {...props} />
      </FormControl>
    );
  }
  return (
    <Field_Text data={field as FragmentType<TypedDocumentNode<FieldBaseFragment>>} {...props} />
  );
});
