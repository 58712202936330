import { OutlinedTextFieldProps, TextField } from '@mui/material';

export type DefaultTextFieldProps = Partial<OutlinedTextFieldProps>;

export const DefaultTextField = (props: DefaultTextFieldProps) => {
  if (props.type === 'hidden') {
    return (
      <input
        type="hidden"
        {...{
          value: `${props.value}`,
          name: props.name,
          id: props.id,
        }}
      />
    );
  }
  return <TextField variant="outlined" fullWidth {...props} />;
};
