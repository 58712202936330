'use client';

import { gql } from '@/__generated__';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Typography,
} from '@mui/material';
import { useController } from 'react-hook-form';
import { createField } from '../lib/form/createFieldControl';
import FieldErrors from './FieldErrors';

export const FIELD_AGREE_FRAGMENT = gql(`
  fragment fieldAgree on Field_Agree {
    ...fieldBase
    __typename
    uncheckedValue
    checkedValue
    descriptionHtml
    defaultState
  }
`);

export const Field_Agree = createField(FIELD_AGREE_FRAGMENT, (field, { methods }) => {
  const {
    label,
    handle,
    instructions,
    descriptionHtml,
    checkedValue,
    // uncheckedValue,
    labelPosition,
    defaultValue = null,
  } = field;

  const required = field.required ?? false;

  const uncheckedValue = null;

  const { control } = methods;

  const {
    field: fieldControl,
    fieldState: { error },
  } = useController({
    name: handle,
    control,
    defaultValue,
  });

  return (
    <FormControl required={required} error={!!error}>
      <FormLabel component="legend" sx={{ mb: 1 }} hidden={labelPosition === 'hidden'}>
        {label}
      </FormLabel>
      <FormControlLabel
        {...{
          componentsProps: {
            typography: { color: 'inherit' },
          },
          required,
          label: <Typography component="span">{descriptionHtml}</Typography>,
          control: (
            <Checkbox
              onChange={(_e, checked) =>
                fieldControl.onChange(checked ? checkedValue : uncheckedValue)
              }
              checked={fieldControl.value === checkedValue}
              value={checkedValue}
            />
          ),
        }}
      />
      <FormHelperText>{instructions}</FormHelperText>
      <FieldErrors errors={error} />
    </FormControl>
  );
});
