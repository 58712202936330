import React from 'react';

export type ErrorFallbackProps = React.ComponentPropsWithoutRef<'div'>;

const ErrorFallback = (props: ErrorFallbackProps) => {
  const { children = `A problem occurred while loading the form.`, ...rest } =
    props;

  return <div {...rest}>{children}</div>;
};

export default ErrorFallback;
