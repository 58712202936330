'use client';

import { useApolloClient } from '@apollo/client';
import { useSearchParams } from 'next/navigation';
import React from 'react';
import { getFormPaymentField } from '../components/Payments/utils/formHasPaymentField';
import useFormieCaptchaFragments from '../components/Recaptcha/useFormieCaptchaFragments';
import { FORMIE_FORM_SUBMISSION_UID_PARAM } from '../constants';
import type { FormProps } from '../Form/Form';
import { type FormieSearchParams, type FormieState } from '../types';
import { devError, devLog } from './devLog';
import { formieDefaultState } from './formieContext';
import { parseSubmission } from './isSubmissionMeta';
import createFormSubmissionQuery from './submission/createFormSubmissionQuery';

export interface UseFormieInitialStateError {
  isError: true;
  isLoading: false;
  formieState: null;
}

export interface UseFormieInitialStateResolving {
  isError: false;
  isLoading: true;
  formieState: null;
}

export interface UseFormieInitialStateResolved {
  isError: false;
  isLoading: false;
  formieState: FormieState;
}

export type UseFormieInitialStateResult =
  | UseFormieInitialStateResolving
  | UseFormieInitialStateResolved
  | UseFormieInitialStateError;

export default function useFormieInitialState(props: FormProps): UseFormieInitialStateResult {
  const [isLoading, setIsLoading] = React.useState(true);
  const [isError, setIsError] = React.useState(false);
  const [state, setState] = React.useState<FormieState | null>(null);
  const searchParams = useSearchParams();

  const apolloClient = useApolloClient();

  const paramsRecord: FormieSearchParams = Array.from(searchParams.entries()).reduce(
    (acc, [key, value]) => {
      acc[key] = value;
      return acc;
    },
    {} as FormieSearchParams,
  );

  const { form, defaultValues, hideRecaptchaBadge } = props;

  const gRecaptchaSettings = useFormieCaptchaFragments({
    data: form?.captchas,
  });

  React.useEffect(() => {
    if (props.loading) return;
    // Set the static initial state

    if (!form || !form.uid || !form.handle) {
      devError('useFormieInitialState', form);
      setIsError(true);
      setIsLoading(false);
      return;
    }

    let initialState: FormieState = {
      ...formieDefaultState,
      form,
      formHandle: form.handle,
      searchParams: paramsRecord,
      gRecaptchaSettings,
      defaultValues,
      showGRecaptchaPolicy: hideRecaptchaBadge,
    };

    const paymentField = getFormPaymentField(props.form);

    const paymentFieldHandle = paymentField?.handle;

    if (paymentField) {
      initialState.payment = {
        fieldHandle: paymentField.handle,
        fieldId: paymentField.id,
        hasPayment: true,
        payment: null,
      };
    }

    const formUid = form.uid;

    const submissionUidParam = searchParams.get(FORMIE_FORM_SUBMISSION_UID_PARAM);

    const isSubmission = submissionUidParam;

    if (!isSubmission) {
      setState(initialState);
      setIsLoading(false);
      return;
    }

    const completedSubmissionQuery = createFormSubmissionQuery(form, {
      uid: submissionUidParam,
      form: formUid,
      isIncomplete: false,
      isSpam: false,
    });

    // const inCompleteSubmissionQuery = createFormSubmissionQuery(form, {
    //   uid: submissionUidParam,
    //   form: formUid,
    //   isIncomplete: true,
    //   isSpam: false,
    // });

    if (!completedSubmissionQuery /* || !inCompleteSubmissionQuery */) {
      devError('useFormieInitialState', 'Query is empty');
      setIsError(true);
      setIsLoading(false);
      return;
    }

    // const res = await apolloClient.query({
    //   query: completedSubmissionQuery
    // });

    apolloClient
      .query({
        query: completedSubmissionQuery,
      })
      .then((res) => {
        const submission = parseSubmission(res.data.formieSubmission);

        const exists = submission && submission.meta.uid === submissionUidParam;

        if (exists) {
          const { values: retrievedValues, meta } = submission;

          if (paymentFieldHandle) delete retrievedValues[paymentFieldHandle];

          devLog('SUBMISSION FOUND:', retrievedValues);

          initialState = {
            ...initialState,
            retrievedValues,
            submission: meta,
            isSubmitted: true,
          };
        }
        setState(initialState);
        setIsLoading(false);
      })
      .catch((err) => {
        console.warn(err);
        setState(initialState);
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  if (isError) {
    return {
      isError: true,
      isLoading: false,
      formieState: null,
    } satisfies UseFormieInitialStateError;
  }

  if (state && !isLoading) {
    return {
      isError: false,
      isLoading: false,
      formieState: state,
    } satisfies UseFormieInitialStateResolved;
  }

  return {
    isError: false,
    isLoading: true,
    formieState: null,
  } satisfies UseFormieInitialStateResolving;
}
