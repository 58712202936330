'use client';

import { getFragmentData } from '@/__generated__';
import { FormieFormQueryVariables } from '@/__generated__/graphql';
import { useQuery } from '@apollo/client';
import { FormDataProps } from '../Form';
import { FORMIE_FORM_FRAGMENT } from '../gql/fragments/FORMIE_FORM_FRAGMENT';
import { FORMIE_FORM_QUERY } from '../gql/queries/FORMIE_FORM_QUERY';

/**
 * Custom hook for querying form data using FormieFormQuery.
 *
 * @param props - The variables for the FormieFormQuery.
 * @returns An object containing the form data, loading state, and error state.
 */
export default function useFormieQuery(props: FormieFormQueryVariables): FormDataProps {
  const { data, loading, error } = useQuery(FORMIE_FORM_QUERY, {
    variables: props,
  });

  const form = getFragmentData(FORMIE_FORM_FRAGMENT, data?.formieForm);

  return {
    form,
    loading,
    error,
  };
}
