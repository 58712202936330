'use client';

import React from 'react';
import type { FormValues } from '../../lib/formTypes';
import { useFormie } from '../../lib/formieContext';
import useRecaptchaExecute from './useRecaptchaExecute';

export default function useFormieGRecaptcha() {
  const settings = useFormie((s) => s.gRecaptchaSettings);
  const { requestToken, ready } = useRecaptchaExecute();

  const getCaptchaValues = React.useCallback(async (): Promise<FormValues> => {
    if (!settings) return {};

    const { gRecaptchaEnabled, gRecaptchaName, gRecaptchaHandle } = settings;

    if (!ready || !gRecaptchaEnabled) return {};

    const token = await requestToken(gRecaptchaName);
    const newValue = { name: gRecaptchaName, value: token };
    const values: FormValues = {
      [gRecaptchaHandle]: newValue,
    };

    return values;
  }, [settings, ready, requestToken]);

  return {
    ready,
    getCaptchaValues,
  };
}
