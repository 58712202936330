'use client';

import { gql } from '@/__generated__';
import { useController } from 'react-hook-form';
import { createField } from '../lib/form/createFieldControl';
import useFieldOptions from '../lib/parse/useFieldOptions';
import { DefaultSelectField } from './DefaultSelectField/DefaultSelectField';
import FieldErrors from './FieldErrors';

export const FIELD_DROPDOWN_FRAGMENT = gql(`
  fragment fieldDropdown on Field_Dropdown {
    __typename
    ...fieldBase
    multi
    options {
      ...fieldOption
    }
  }
`);

export const Field_Dropdown = createField(FIELD_DROPDOWN_FRAGMENT, (field, { methods }) => {
  const {
    options: _options,
    defaultValue: maybeDefaultValue,
    handle,
    label,
    placeholder,
    required,
    instructions,
  } = field;

  const { defaultValue: defaultValueSingle, defaultValues, options } = useFieldOptions(_options);

  const multi = field.__typename === 'Field_Dropdown' ? field.multi : false;

  const { control } = methods;
  const defaultValue = multi
    ? defaultValues
    : (maybeDefaultValue ?? defaultValueSingle ?? options[0]?.value ?? '');

  const {
    field: { ref, ...controlledField },
    fieldState: { error },
  } = useController({
    name: handle,
    control,
    defaultValue,
  });

  return (
    <>
      <DefaultSelectField
        {...{
          ...controlledField,
          SelectProps: {
            ref,
            multiple: Boolean(multi),
          },
          error: !!error,
          helperText: instructions,
          label,
          placeholder,
          required,
          options,
        }}
      />
      <FieldErrors errors={error} />
    </>
  );
});
