'use client';

import { gql } from '@/__generated__';
import { createField } from '../lib/form/createFieldControl';
import Payments from './Payments';
import usePaymentField from './Payments/utils/usePaymentField';

export const FIELD_PAYMENT_FRAGMENT = gql(`
  fragment fieldPayment on Field_Payment {
    __typename
    ...fieldBase
    paymentIntegration
    paymentIntegrationType
    providerSettings
  }
`);

export const Field_Payment = createField(FIELD_PAYMENT_FRAGMENT, (field) => {
  const settings = usePaymentField(field);

  if (!settings) return null;

  // console.log('Field_Payment', settings);

  return <Payments settings={settings} />;
});
