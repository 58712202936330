import { gql } from '@/__generated__';

export const FIELD_FRAGMENT = gql(`
  fragment field on FieldInterface {
    __typename
    id
    handle
    conditions
    enableConditions
    ...fieldBase
    ...fieldAddress
    ...fieldAgree
    ...fieldCheckboxes
    ...fieldDropdown
    ...fieldDate
    ...fieldName
    ...fieldRadio
    ...fieldFileUpload
    ...fieldPayment
    ...fieldNamePrefix
    ...fieldAddressCountry
    #
    ...fieldSection
    ...fieldHeading
  }
`);
