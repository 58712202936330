import { Alert } from '@mui/material';

export interface PaymentLoadingProps {
  message?: string;
}

export const PaymentLoading = ({
  message = 'Loading...',
}: PaymentLoadingProps) => {
  return <Alert severity="info">{message}</Alert>;
};

export interface PaymentErrorProps {
  error?: Error | string;
}

const isDev = process.env.NODE_ENV === 'development';

export const PaymentError = ({ error }: PaymentErrorProps) => {
  const defaultMessage = 'An error occurred';

  const message =
    error instanceof Error
      ? isDev
        ? error.message
        : defaultMessage
      : (error ?? defaultMessage);

  return <Alert severity="error">{message}</Alert>;
};
