import React from 'react';
import { useFormContext } from 'react-hook-form';
import type { AmountSettings, PaymentSettings } from '../types';
import { parseAmount, parseDynamicAmount } from './paymentUtils';

export default function usePaymentAmount(
  settings: PaymentSettings | null,
  defaultAmount: number = 0,
): AmountSettings | null {
  // if (!settings) {
  //   return {
  //     amount: defaultAmount,
  //   };
  // }
  const {
    amountField,
    currencyField,
    amount: maybeAmount,
    currency: maybeCurrency,
  } = settings ?? {};

  const [state, setState] = React.useState<AmountSettings | null>(null);

  const methods = useFormContext();

  const watchValue = amountField ? methods.watch(amountField) : undefined;

  const watchCurrency = currencyField ? methods.watch(currencyField) : undefined;

  React.useEffect(() => {
    /**
     * If the amount is fixed, parse it and return it.
     * @note Fixed amounts should set to whole numbers (cents) and not be multiplied.
     */
    const fixedAmount = parseAmount(maybeAmount, true) ?? null;

    const currentAmount = fixedAmount ?? defaultAmount;
    const currentCurrency = maybeCurrency;
    let dynamicAmount: number | undefined = undefined;

    try {
      dynamicAmount = amountField ? parseDynamicAmount(methods.getValues(amountField)) : undefined;
    } catch {
      methods.setError(amountField ?? 'root', {
        type: 'parseAmount',
        message: 'Invalid amount',
      });
    }

    const nextAmount = amountField ? dynamicAmount : currentAmount;

    const nextCurrency = currencyField ? methods.getValues(currencyField) : currentCurrency;

    const hasChanges = !state || nextAmount !== currentAmount || nextCurrency !== currentCurrency;

    if (!hasChanges) return;

    const amount = nextAmount ?? defaultAmount;
    const currency = nextCurrency;
    const amountFormatted = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
    }).format(amount / 100);

    setState({
      amount,
      amountFormatted,
      currency,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchValue, watchCurrency]);

  return state;
}
