import { gql } from '@/__generated__';

export const FORMIE_CAPTCHA_FRAGMENT = gql(`
  fragment formieCaptcha on FormieCaptchaType {
    __typename
    handle
    name
    value
  }
`);
