import { gql } from '@/__generated__';

export const FIELD_BASE_FRAGMENT = gql(`
  fragment fieldBase on FieldInterface {
    __typename
    id
    conditions
    containerAttributes { label, value}
    cssClasses
    defaultValue
    displayName
    emailValue
    enableConditions
    enabled
    errorMessage
    handle
    inputAttributes { label, value }
    inputTypeName
    instructions
    label
    labelPosition
    matchField
    placeholder
    prePopulate
    required
    type
    typeName
    uid
    visibility
  }`);
