import { FieldOption } from '@/__generated__/graphql';
import { MenuItem, OutlinedTextFieldProps, SelectProps, TextField } from '@mui/material';

export type DefaultSelectFieldProps = Partial<OutlinedTextFieldProps> & {
  options?: FieldOption[];
};

export const DefaultSelectField = ({
  options = [],
  SelectProps: maybeSelectProps,
  ...props
}: DefaultSelectFieldProps) => {
  const selectProps: SelectProps = {
    ...maybeSelectProps,
  } as SelectProps;

  const isNative = !!selectProps.native;

  return (
    <TextField variant="outlined" value="" select SelectProps={selectProps} fullWidth {...props}>
      {options?.map(({ value, label }) =>
        isNative ? (
          <option key={value} value={value ?? ''}>
            {label}
          </option>
        ) : (
          <MenuItem key={value} value={value ?? ''}>
            {label}
          </MenuItem>
        ),
      )}
    </TextField>
  );
};
