'use client';

import { usePathname } from 'next/navigation';
import React from 'react';
import { FORMIE_FORM_SUBMISSION_UID_PARAM } from '../constants';
import { devError } from './devLog';
import { useFormie } from './formieContext';

export default function useFormieRedirectUrl() {
  const [mounted, setMounted] = React.useState(false);

  const [redirectUrl, setRedirectUrl] = React.useState<string>(window.location.href);
  const submissionUid = useFormie((s) => s.submission?.uid);
  const formUid = useFormie((s) => s.form.uid);

  const pathname = usePathname();

  React.useEffect(() => {
    if (!mounted) {
      setMounted(true);
      return;
    }
    try {
      const currentUrl = new URL(pathname, window.location.href);

      if (submissionUid) {
        currentUrl.searchParams.set(FORMIE_FORM_SUBMISSION_UID_PARAM, submissionUid);
      }

      setRedirectUrl(currentUrl.toString());
    } catch (err) {
      devError(err);
    }
  }, [mounted, submissionUid, formUid, pathname]);

  return redirectUrl;
}
