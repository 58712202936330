import { FieldBaseFragment } from '@/__generated__/graphql';
import { createFragmentParser } from '@liquorice/gql-utils';
import { OverrideProps } from '@liquorice/utils';
import { FIELD_BASE_FRAGMENT } from '../../gql/fragments/FIELD_BASE_FRAGMENT';

export type FieldBase<T extends FieldBaseFragment = FieldBaseFragment> = OverrideProps<
  T,
  {
    id: string;
    handle: string;
    required: boolean;
    disabled: boolean;
    label: string;
    placeholder: string;
  }
>;

const parseFieldBase = createFragmentParser(
  FIELD_BASE_FRAGMENT,
  <T extends FieldBaseFragment>(data: T | null): FieldBase<T> | null => {
    if (!data) return null;

    return {
      ...data,
      disabled: !data.enabled,
      id: data.id ?? '',
      handle: data.handle ?? '',
      required: !!data.required,
      label: data.label ?? '',
      placeholder: data.placeholder ?? '',
    } as FieldBase<T>;
  },
);

export default parseFieldBase;
