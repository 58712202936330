import {
  PaymentProviderSettingEnum,
  PaymentProviderTypeEnum,
  type Amount,
  type MaybePaymentField,
  type PaymentProviderSettings,
  type PaymentProviderSettingType,
  type PaymentProviderType,
  type PaymentSettings,
} from '../types';

export const getPaymentConstants = () => {
  const DEFAULT_ERROR_MESSAGE = 'There was an issue with your payment';

  const PAYMENT_REDIRECT_URL = process.env.NEXT_PUBLIC_PAYMENT_REDIRECT_URL;

  const PAYMENT_CURRENCY_DEFAULT = process.env.NEXT_PUBLIC_PAYMENT_CURRENCY_DEFAULT || 'USD';

  const STRIPE_CURRENCY_DEFAULT =
    process.env.NEXT_PUBLIC_STRIPE_CURRENCY_DEFAULT || PAYMENT_CURRENCY_DEFAULT;
  const STRIPE_PUBLISHABLE_KEY = process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY;

  const STRIPE_SECRET_KEY = process.env.STRIPE_SECRET_KEY;

  return {
    DEFAULT_ERROR_MESSAGE,
    PAYMENT_CURRENCY_DEFAULT,
    PAYMENT_REDIRECT_URL,
    STRIPE_CURRENCY_DEFAULT,
    STRIPE_PUBLISHABLE_KEY,
    STRIPE_SECRET_KEY,
  };
};

/*
const exampleData = [
  {
    handle: 'paymentFixed',
    providerSettings: {
      stripeSandbox: {
        integration: 'verbb\\\\formie\\\\integrations\\\\payments\\\\Stripe',
        type: 'single',
        amountType: 'fixed',
        currencyType: 'fixed',
        metadata: [],
        amountFixed: '10.99',
        currencyFixed: 'AUD',
      },
    },
    paymentIntegration: 'stripeSandbox',
    paymentIntegrationType: 'verbb\\formie\\integrations\\payments\\Stripe',
  },
  {
    handle: 'paymentDynamic',
    providerSettings: {
      stripeSandbox: {
        integration: 'verbb\\\\formie\\\\integrations\\\\payments\\\\Stripe',
        type: 'single',
        amountType: 'dynamic',
        currencyType: 'fixed',
        metadata: [],
        currencyFixed: 'AUD',
        amountVariable: '{field:amount}',
      },
    },
    paymentIntegration: 'stripeSandbox',
    paymentIntegrationType: 'verbb\\formie\\integrations\\payments\\Stripe',
  },
];
*/

export const isSettingType = (value: unknown): value is PaymentProviderSettingType => {
  return Object.values(PaymentProviderSettingEnum).includes(value as PaymentProviderSettingEnum);
};

export const parseDynamicFieldName = (field: string): string => {
  const matches = field.match(/{field:(.*)}/);

  if (!matches || matches.length < 2) {
    throw new Error(`Invalid dynamic field name: ${field}`);
  }

  return matches[1];
};

const parseIntegrationType = (integrationType?: string | null): PaymentProviderType => {
  const type = integrationType?.split('\\').pop()?.toLowerCase();

  if (!type) {
    throw new Error(`Invalid payment integration type: ${integrationType}`);
  }

  switch (type) {
    case 'stripe':
      return PaymentProviderTypeEnum.stripe;
    // case 'paypal':
    //   return PaymentProviderTypeEnum.paypal;
    default:
      throw new Error(`Unknown payment integration type: ${integrationType}`);
  }
};

export const parseProviderSettings = (
  maybeData: MaybePaymentField,
): PaymentProviderSettings | null => {
  if (!maybeData) {
    return null;
  }

  const { handle: fieldHandle, providerSettings, paymentIntegration } = maybeData;

  if (!providerSettings || !paymentIntegration) {
    return null;
  }

  const data = JSON.parse(providerSettings);

  const settings = data[paymentIntegration];

  const type = parseIntegrationType(settings.integration);

  const amountType: PaymentProviderSettingType = isSettingType(settings.amountType)
    ? settings.amountType
    : PaymentProviderSettingEnum.fixed;
  const currencyType: PaymentProviderSettingType = isSettingType(settings.currencyType)
    ? settings.currencyType
    : PaymentProviderSettingEnum.fixed;

  const paymentSettings: PaymentSettings = {};

  if (amountType === PaymentProviderSettingEnum.fixed) {
    paymentSettings.amount = settings.amountFixed;
  } else {
    // paymentSettings.amountField = settings.amountVariable;
    paymentSettings.amountField = parseDynamicFieldName(settings.amountVariable);
  }

  if (currencyType === PaymentProviderSettingEnum.fixed) {
    paymentSettings.currency = settings.currencyFixed;
  } else {
    paymentSettings.currencyField = parseDynamicFieldName(settings.currencyVariable);
  }

  return {
    ...paymentSettings,
    fieldHandle,
    type,
    amountType,
    currencyType,
    metadata: settings.metadata,
  };
};

export function assertPositiveNumber(value: unknown): asserts value is number {
  if (typeof value !== 'number' || isNaN(value) || value < 0) {
    throw new Error(`Invalid number: ${value}`);
  }
}

export const parseAmount = (
  amount?: Amount | null,
  multiply: boolean | null = null,
  shouldThrow = false,
): number | undefined => {
  try {
    let amountStr = typeof amount === 'string' ? amount : undefined;

    // Regex to remove commas from string
    const numberRe = /[^0-9.-]+/g;

    amountStr = amountStr?.replace(numberRe, '');

    let amountNum = Number(amountStr || amount);

    assertPositiveNumber(amountNum);

    const isDecimal = (amountNum ?? 0) % 1 !== 0;

    const shouldMultiply = multiply ?? (isDecimal && multiply !== false);

    if (shouldMultiply) amountNum = amountNum * 100;

    return amountNum;
  } catch (err) {
    if (shouldThrow) {
      throw err;
    }

    return undefined;
  }
};

export const parseDynamicAmount = (amount?: Amount): number | undefined => {
  return parseAmount(amount, true, true);
};

export const formatAmount = (amount: Amount, currency?: string, multiply?: boolean | null) => {
  const amountNum = parseAmount(amount, multiply);

  if (amountNum === undefined) {
    return '';
  }

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  }).format(amountNum / 100);
};
