import {
  Collapse,
  FormHelperText,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';

import { FieldError } from 'react-hook-form';

export type FieldErrorsProps = {
  errors?: FieldError | FieldError[];
};

const FieldErrors = ({ errors = [] }: FieldErrorsProps) => {
  if (!Array.isArray(errors)) {
    errors = [errors];
  }

  return (
    <Collapse in={!!errors.length} unmountOnExit>
      <List component="ul" disablePadding>
        {errors.map((v, i) => (
          <ListItem key={i} disableGutters dense>
            <ListItemText>
              <FormHelperText error>{v.message}</FormHelperText>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Collapse>
  );
};

export default FieldErrors;
