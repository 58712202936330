import { FetchResult, HttpLink, useApolloClient } from '@apollo/client';
import { keys } from '@liquorice/utils';
import React from 'react';
import { useFormContext, type FieldValues } from 'react-hook-form';
import { useFormieGRecaptcha } from '../components/Recaptcha';
import { devError, devLog } from './devLog';
import { useFormie } from './formieContext';
import { FormErrors, FormValues } from './formTypes';
import createFormSubmissionMutation, {
  FormSubmissionMutation,
  type GenericFormSubmissionFragment,
} from './submission/createFormSubmissionMutation';
import parseFormSubmissionErrors from './submission/parseFormSubmissionErrors';
import nullifyEmptyValues from './utils/nullifyEmptyValues';

export interface FormSubmissionMutationSuccess<T extends string = string>
  extends FormSubmissionMutation<T> {
  submission: GenericFormSubmissionFragment<T>;
}

export const isFormSubmissionMutationSuccess = (
  result: FormSubmissionMutation | null,
): result is FormSubmissionMutationSuccess => {
  return !!result?.submission?.uid;
};

export interface SubmitFormieFormSuccess {
  errors?: null;
  result: FormSubmissionMutationSuccess;
  success: true;
}
export interface SubmitFormieFormFailure {
  errors?: FormErrors;
  result?: FormSubmissionMutation | null;
  success: false;
}

export type SubmitFormieFormResult = SubmitFormieFormSuccess | SubmitFormieFormFailure;

const createResponse = (
  result?: FormSubmissionMutation | null,
  errors?: FormErrors | null,
): SubmitFormieFormResult => {
  if (result && (!errors || Object.keys(errors).length === 0)) {
    if (isFormSubmissionMutationSuccess(result)) {
      return {
        errors: null,
        result,
        success: true,
      };
    }
  }
  devError('createResponse', errors);

  return {
    errors: errors ?? { error: ['An error occurred.'] },
    result,
    success: false,
  };
};

const FORMS_ENDPOINT = process.env.NEXT_PUBLIC_FORMIE_SUBMISSION_ENDPOINT ?? '/api/forms';

export type UseFormieSubmitTriggerVars = [formValues: FormValues];

export default function useFormieSubmit() {
  const client = useApolloClient();
  const methods = useFormContext();
  const _form = useFormie((s) => s.form);
  const submission = useFormie((s) => s.submission);
  const setIsSubmitting = useFormie((s) => s.setIsSubmitting);
  const submitErrorHandler = useFormie((s) => s.submitErrorHandler);
  const setIsSubmitError = useFormie((s) => s.setIsSubmitError);
  const setIsSubmitted = useFormie((s) => s.setIsSubmitted);
  const paymentState = useFormie((s) => s.payment);
  const validateOnly = useFormie((s) => s.validateOnly);
  const ignoreErrors = useFormie((s) => s.ignoreErrors);

  const { getCaptchaValues } = useFormieGRecaptcha();

  const reject = (error: Error) => {
    devError('useFormieSubmit', error);
    setIsSubmitError(error);
    return error;
  };

  const resolve = (res: SubmitFormieFormResult) => {
    if (!res.success) {
      setIsSubmitError(new Error('No submission UID found'));
      methods.clearErrors();

      Object.entries(res.errors ?? {}).forEach(([key, value]) => {
        methods.setError(key, {
          type: 'manual',
          message: value.join(', '),
        });
      });
    } else {
      setIsSubmitted(true, res.result.submission);
    }
    return res;
  };

  const onSubmit = React.useCallback(
    async (formValues: FieldValues) => {
      setIsSubmitting();

      devLog(formValues);
      devLog('ignoreErrors', ignoreErrors);
      devLog('validateOnly', validateOnly);

      const formUid = _form.uid;

      if (!formUid) {
        return reject(new Error('No form UID found'));
      }

      client.setLink(new HttpLink({ uri: `${FORMS_ENDPOINT}/${formUid}/` }));

      const mutationName = _form.submissionMutationName;

      if (!mutationName) {
        return reject(new Error('No mutation name found'));
      }

      // Add any captcha values to the form values
      const captchaValues = getCaptchaValues ? await getCaptchaValues() : {};
      formValues = { ...formValues, ...captchaValues };

      // if (validateOnly) {
      //   formValues.isIncomplete = true;
      // } else {
      //   formValues.isIncomplete = false;
      // }

      const paymentFieldHandle = paymentState?.fieldHandle;

      const doingPayment = !!(paymentFieldHandle && formValues[paymentFieldHandle]);

      if (formValues.isIncomplete && doingPayment) {
        return reject(new Error('Cannot have payment data on an incomplete submission'));
      }

      const submissionData = nullifyEmptyValues(formValues);

      if (submission) submissionData.uid = submission.uid;

      const mutation = createFormSubmissionMutation({
        mutationName,
        submissionData,
      });

      if (!mutation) {
        return reject(new Error('Invalid mutation'));
      }

      try {
        const res = await client
          .mutate({
            mutation,
          })
          .then((res) => {
            const errors = parseFormSubmissionErrors(res, ignoreErrors);

            const maybeResult = (res as FetchResult<FormSubmissionMutation> | null)?.data ?? null;

            const response = createResponse(maybeResult, errors);

            return response;
          })
          .catch((error) => {
            const formErrors = parseFormSubmissionErrors(error, ignoreErrors);
            return createResponse(null, formErrors);
          });

        if (res.errors) {
          methods.clearErrors();

          keys(res.errors).forEach((key) => {
            methods.setError(key, {
              type: 'manual',
              message: res.errors?.[key].join(', '),
            });
          });
        }

        return resolve(res);
      } catch (error) {
        console.error(error);
        return reject(error as Error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      client,
      _form,
      submission,
      validateOnly,
      ignoreErrors,
      paymentState,
      setIsSubmitting,
      setIsSubmitError,
      setIsSubmitted,
      getCaptchaValues,
    ],
  );

  const triggerSubmit = async () => {
    methods.clearErrors();
    methods.trigger().then((valid) => {
      if (valid) {
        methods.handleSubmit(onSubmit, submitErrorHandler)();
      }
    });
  };

  return {
    onSubmit,
    triggerSubmit,
    submission,
  };
}
