import { createFragmentArrayParser } from '@liquorice/gql-utils';
import { FIELD_OPTION_FRAGMENT } from '../../gql/fragments/FIELD_OPTION_FRAGMENT';
import { FieldOptionsResult } from './parseFieldOptions';

const useFieldOptions = createFragmentArrayParser(
  FIELD_OPTION_FRAGMENT,
  (data): FieldOptionsResult => {
    const options = data.map(({ value, label, isDefault }) => ({
      value: value ?? '',
      label: label ?? '',
      isDefault: !!isDefault,
    }));

    const defaultValues = options.reduce((acc, v) => {
      if (v.isDefault) {
        acc.push(v.value);
      }
      return acc;
    }, [] as string[]);

    const defaultValue = defaultValues[0] ?? undefined;

    return {
      options,
      defaultValue,
      defaultValues,
    };
  },
);

export default useFieldOptions;
