'use client';

import FormieStripeProvider from './providers/stripe/FormieStripe';
import type { HandlesPayment, PaymentProviderSettings } from './types';
import usePaymentAmount from './utils/usePaymentProviderSettings';

export interface PaymentsProviderProps extends HandlesPayment {
  settings: PaymentProviderSettings;
}

const Payments = ({ settings }: PaymentsProviderProps) => {
  const paymentAmount = usePaymentAmount(settings);

  if (!paymentAmount) return null;

  const { amount, currency } = paymentAmount;

  switch (settings.type) {
    case 'stripe':
      return <FormieStripeProvider {...settings} amount={amount} currency={currency} />;
    default:
      return <div>Unknown payment provider: {settings.type}</div>;
  }
};

export default Payments;
