'use client';

import { gql } from '@/__generated__';
import { addToDistinctArray, removeFromDistinctArray } from '@liquorice/utils';
import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from '@mui/material';
import { useController } from 'react-hook-form';
import { createField } from '../lib/form/createFieldControl';
import useFieldOptions from '../lib/parse/useFieldOptions';
import FieldErrors from './FieldErrors';

export const FIELD_CHECKBOXES_FRAGMENT = gql(`
  fragment fieldCheckboxes on Field_Checkboxes {
    __typename
    ...fieldBase
    multi
    layout
    options {
      ...fieldOption
    }
  }
`);

export const Field_Checkboxes = createField(FIELD_CHECKBOXES_FRAGMENT, (field, { methods }) => {
  const {
    label,
    handle,
    layout,
    required,
    options: _options,
    multi: _multi,
    instructions,
    labelPosition,
  } = field;

  const { defaultValues, options } = useFieldOptions(_options);

  const {
    field: fieldControl,
    fieldState: { error },
  } = useController({
    name: handle,
    control: methods.control,
    defaultValue: defaultValues,
  });

  return (
    <FormControl required={required} /* error={error} */>
      <FormLabel
        component="legend"
        sx={{ mb: 1 }}
        hidden={labelPosition === 'hidden'}
        error={!!error}>
        {label}
      </FormLabel>
      <FieldErrors errors={error} />
      <FormHelperText>{instructions}</FormHelperText>
      <FormGroup row={layout === 'horizontal'} sx={{ mb: 1 }}>
        {options.map((v) => {
          const fieldProps: CheckboxProps = {
            value: v.value,
            onChange: (_e, checked) => {
              // const value = addToDistinctArray
              const currentValue = Array.isArray(fieldControl.value)
                ? (fieldControl.value as string[])
                : [];

              if (checked) {
                fieldControl.onChange(addToDistinctArray(currentValue, v.value));
              } else {
                fieldControl.onChange(removeFromDistinctArray(currentValue, v.value));
              }
            },
          };
          return (
            <FormControlLabel
              key={v.value}
              label={v.label}
              control={<Checkbox style={{ color: 'currentColor' }} {...fieldProps} />}
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
});
