'use client';

import { FragmentType } from '@/__generated__';
import { FieldBaseFragment } from '@/__generated__/graphql';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { withFragment } from '@liquorice/gql-utils';
import type { SxProps } from '@mui/material';
import { Box, BoxProps } from '@mui/material';
import { FIELD_FRAGMENT } from '../../gql/fragments/FIELD_FRAGMENT';
import { FormMethodsProps } from '../../lib/formTypes';
import useFieldConditions from '../../lib/useFieldConditions';
import withErrorBoundary from '../ErrorBoundary/withErrorBoundary';
import { Field_Address } from '../Field_Address';
import { Field_AddressCountry } from '../Field_AddressCountry';
import { Field_Agree } from '../Field_Agree';
import { Field_Checkboxes } from '../Field_Checkboxes';
import Field_Date from '../Field_Date';
import { Field_Dropdown } from '../Field_Dropdown';
import { Field_FileUpload } from '../Field_FileUpload';
import { Field_Heading } from '../Field_Heading';
import { Field_Name } from '../Field_Name';
import { Field_NamePrefix } from '../Field_NamePrefix';
import { Field_Payment } from '../Field_Payment';
import { Field_Radio } from '../Field_Radio';
import { Field_Section } from '../Field_Section';
import { Field_Text } from '../Field_Text';

export type FormieFieldProps = {
  parentHandle?: string;
} & FormMethodsProps;

const FormieField = withErrorBoundary(
  withFragment(FIELD_FRAGMENT, (field, { methods, parentHandle }: FormieFieldProps) => {
    const show = useFieldConditions(field, methods);

    if (show === false) {
      return null;
    }

    if (!field) return null;

    field = {
      ...field,
      // Append the parent handle if we have one
      handle: parentHandle ? `${parentHandle}.${field.handle}` : field.handle,
    };

    // Calculate any conditions
    // TODO: Implement conditions
    // const conditionalShow = useFormFieldCondition(field.conditions, field.enableConditions);

    const boxProps: BoxProps = {};

    const boxStyle: SxProps = {
      flexGrow: 1,
      flexBasis: 100,
    };

    let inner: JSX.Element | null = null;

    switch (field.__typename) {
      case 'Field_Heading':
        inner = <Field_Heading field={field} methods={methods} />;
        break;

      case 'Field_Section':
        inner = <Field_Section field={field} methods={methods} />;
        break;
      case 'Field_Address':
        inner = <Field_Address field={field} methods={methods} />;
        break;
      case 'Field_Name':
        inner = <Field_Name field={field} methods={methods} />;
        break;
      case 'Field_Agree':
        inner = <Field_Agree field={field} methods={methods} />;
        break;
      case 'Field_NamePrefix':
        boxStyle.width = 100;
        boxStyle.flexGrow = 0;
        inner = <Field_NamePrefix field={field} methods={methods} />;
        break;
      case 'Field_AddressCountry':
        inner = <Field_AddressCountry field={field} methods={methods} />;
        break;
      case 'Field_Date':
        inner = <Field_Date field={field} methods={methods} />;
        break;
      case 'Field_Dropdown':
        inner = <Field_Dropdown field={field} methods={methods} />;
        break;
      case 'Field_Checkboxes':
        inner = <Field_Checkboxes field={field} methods={methods} />;
        break;
      case 'Field_Radio':
        inner = <Field_Radio field={field} methods={methods} />;
        break;
      case 'Field_Payment':
        inner = <Field_Payment field={field} methods={methods} />;
        break;
      case 'Field_FileUpload':
        inner = <Field_FileUpload field={field} methods={methods} />;
        break;

      default:
        inner = (
          <Field_Text
            data={field as FragmentType<TypedDocumentNode<FieldBaseFragment>>}
            methods={methods}
          />
        );
    }

    return (
      <Box {...boxProps} sx={boxStyle}>
        {inner}
      </Box>
    );
  }),
);
// );

export default FormieField;
