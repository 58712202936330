import { withFragmentArray } from '@liquorice/gql-utils';
import { FORMIE_CAPTCHA_FRAGMENT } from './FORMIE_CAPTCHA_FRAGMENT';
import parseFormieCaptchas from './parseFormieCaptchas';

export type GRecaptchaSettings =
  | {
      gRecaptchaEnabled: false;
      gRecaptchaHandle?: string;
      gRecaptchaName?: string;
      gRecaptchaSiteKey?: string;
    }
  | {
      gRecaptchaEnabled: true;
      gRecaptchaHandle: string;
      gRecaptchaName: string;
      gRecaptchaSiteKey: string;
    };

const useFormieCaptchaFragments = withFragmentArray(
  FORMIE_CAPTCHA_FRAGMENT,
  (captchas) => {
    const { name, handle, value } =
      parseFormieCaptchas(captchas).recaptchaCaptcha ?? {};

    const formHasCaptchas = !!handle && !!name && !!value;

    return {
      gRecaptchaHandle: handle,
      gRecaptchaEnabled: formHasCaptchas,
      gRecaptchaName: name,
      gRecaptchaSiteKey: value,
    } as GRecaptchaSettings;
  },
);

export default useFormieCaptchaFragments;
