'use client';

import { gql } from '@/__generated__';
import { FluidContainer } from '@/components/Atoms/Layouts';
import createBlock, { BlockMeta } from '@/components/Blocks/createBlock';
import { Formie } from '@/formie-react/src';
import { ThemeProvider } from '@emotion/react';
import { firstNonNullable } from '@liquorice/utils';
import { genuTheme } from './MuiTheme';

const FORM_BLOCK_FRAGMENT = gql(`
  fragment FormBlock on blocks_blockForm_BlockType {
    heading
    htmlContentSimple
    form {
      id
    }
  }
`);

export interface FormBlockProps extends BlockMeta {}

const FormBlock = createBlock(FORM_BLOCK_FRAGMENT, (data, props: FormBlockProps) => {
  const formId = firstNonNullable(data.form)?.id;

  return (
    <FluidContainer width="article" id={props.anchor}>
      {data.heading && <h3 className="mb-8">{data.heading}</h3>}
      <div
        className="genu-tables prose max-w-none space-y-[1em] prose-headings:mb-[0.5em] prose-li:m-0"
        dangerouslySetInnerHTML={{ __html: data.htmlContentSimple || '' }}
      />
      <ThemeProvider theme={genuTheme}>
        {formId && <Formie queryVariables={{ id: formId }} hideRecaptchaBadge />}
      </ThemeProvider>
    </FluidContainer>
  );
});

export default FormBlock;
