import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { fetchGql } from '@liquorice/gql-utils';
import type { NextRequest } from 'next/server';
import { FORMIE_FORM_EXISTS_QUERY } from '../gql/queries/FORMIE_FORM_EXISTS_QUERY';

export interface SubmissionHandlerOptions {
  /**
   * A function that returns the Apollo Client instance.
   */
  getClient: () =>
    | Promise<ApolloClient<NormalizedCacheObject>>
    | ApolloClient<NormalizedCacheObject>;
}

const rejectResponse = (error?: string) =>
  new Response(
    JSON.stringify({
      success: false,
      error: error || 'Submission failed',
    }),
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

/**
 * Creates a submission handler function that handles POST requests for form submissions.
 *
 * @param {SubmissionHandlerOptions} options - The options for the submission handler.
 * @returns {Object} - An object containing the POST method for handling form submissions.
 */
export default function createSubmissionHandler({
  getClient,
}: SubmissionHandlerOptions) {
  return async (
    req: NextRequest,
    ctx: { params: Promise<{ uid: string }> },
  ) => {
    try {
      const client = await getClient();

      const params = await ctx.params;

      const uid = params.uid;
      console.log('uid', uid);
      if (!uid) {
        return rejectResponse('Invalid form UID');
      }

      const formExists = client
        .query({
          query: FORMIE_FORM_EXISTS_QUERY,
          variables: {
            uid: params.uid,
          },
        })
        .then((res) => res.data.formieForm?.submissionMutationName);

      if (!formExists) {
        return rejectResponse('Form not found');
      }

      const body = req && 'json' in req ? await req.json() : {};

      return await fetchGql({ body });
    } catch (error) {
      console.error('Error handling submission:', error);
      return rejectResponse('Error handling submission');
    }
  };
}
