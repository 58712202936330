// import { FieldBase_Field_Payment_Fragment } from '@/__generated__/graphql';
import type { FormieFormFragment } from '@/__generated__/graphql';
import { FieldBaseFragment, FieldPaymentFragment } from '@/__generated__/graphql';
import type { Maybe } from 'graphql/jsutils/Maybe';
import type { FieldData } from '../../../lib/form/createFieldControl';
import parseFieldBase, { type FieldBase } from '../../../lib/parse/parseFieldBase';

export const isPaymentForm = (form: Maybe<FormieFormFragment>) => {
  return !!form?.paymentFields?.find((field) => field?.__typename === 'Field_Payment');
};

export type PaymentFieldBase = FieldBase<
  Extract<FieldBaseFragment, { __typename: 'Field_Payment' }>
>;

export type PaymentField = FieldData<FieldPaymentFragment>;

export const getFormPaymentField = (form: Maybe<FormieFormFragment>): PaymentField | null => {
  const data = form?.paymentFields?.find(
    (field) => field?.__typename === 'Field_Payment',
  ) as Maybe<FieldPaymentFragment>;

  if (!data) return null;

  const base = parseFieldBase(data) as PaymentFieldBase;

  return {
    ...data,
    ...base,
  } as PaymentField;
};
