import React from 'react';

export type LoadingFallbackProps = React.ComponentPropsWithoutRef<'div'>;

const LoadingFallback = (props: LoadingFallbackProps) => {
  const { children = `Loading...`, ...rest } = props;

  return <div {...rest}>{children}</div>;
};

export default LoadingFallback;
