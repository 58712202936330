'use client';

import { gql } from '@/__generated__';
import { Divider } from '@mui/material';
import { createField } from '../lib/form/createFieldControl';

export const FIELD_SECTION_FRAGMENT = gql(`
  fragment fieldSection on Field_Section {
    __typename
    ...fieldBase
  }
`);

export const Field_Section = createField(FIELD_SECTION_FRAGMENT, (_field, _props) => {
  return <Divider />;
});
