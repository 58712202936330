'use client';

import { FormControl, FormHelperText, Typography } from '@mui/material';

import { gql } from '@/__generated__';
import { createField } from '../lib/form/createFieldControl';
import { FormieNestedRows } from './FormieNestedRows';

export const FIELD_ADDRESS_FRAGMENT = gql(`
  fragment fieldAddress on Field_Address {
    __typename
    ...fieldBase
    nestedRows {
      __typename
      ...formieNestedRow
    }
  }
`);

export const Field_Address = createField(FIELD_ADDRESS_FRAGMENT, (field, props) => {
  const { nestedRows, handle, label, instructions, labelPosition } = field;
  return (
    <FormControl fullWidth>
      <Typography component="legend" sx={{ mb: 2 }} hidden={labelPosition === 'hidden'}>
        {label}
      </Typography>

      {instructions && <FormHelperText>{instructions}</FormHelperText>}

      <FormieNestedRows data={nestedRows} parentHandle={handle} {...props} />
    </FormControl>
  );
});
