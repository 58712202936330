import type { FormiePaymentState } from '@/formie-react/src/types';
import { assertUnreachable, keys } from '@liquorice/utils';
import { Stack } from '@mui/material';
import { usePathname, useRouter } from 'next/navigation';
import React from 'react';
import { useFormContext, type FieldErrors as TFieldErrors } from 'react-hook-form';
import { IS_DEV } from '../../../constants';
import { devClear, devError, devLog } from '../../../lib/devLog';
import { useFormie } from '../../../lib/formieContext';
import useFormieRedirectUrl from '../../../lib/useFormieRedirectUrl';
import useFormieSubmit from '../../../lib/useFormieSubmit';
import FormieSubmitRow from '../../FormieSubmitRow/FormieSubmitRow';
import type { AmountSettings, Payment, UsePaymentReturn } from '../types';
import { formatAmount } from '../utils/paymentUtils';

export interface PaymentSubmitRowProps<
  TData extends Payment = Payment,
  TParsedData extends Payment = Payment,
> extends UsePaymentReturn<TData, TParsedData>,
    AmountSettings {
  children: React.ReactNode;
}

enum PaymentStatus {
  IDLE,
  FORM_VALIDATING,
  FORM_VALIDATED,
  FORM_ERROR,
  CREATING_PAYMENT,
  PAYMENT_CREATED,
  PAYMENT_CONFIRMING,
  PAYMENT_CONFIRMED,
  PAYMENT_PARTIAL_SUBMITTING, // Submitting the payment data
  PAYMENT_PARTIAL_SUBMITTED, // Confirming the payment
  PAYMENT_FINAL_SUBMITTING, // Submitting the payment data
  PAYMENT_FINAL_SUBMITTED,
  PAYMENT_ERROR,
  PAYMENT_SUCCESS,
  CHECKING_PARAMS,
}

export type TPaymentStatus = keyof typeof PaymentStatus | PaymentStatus;

export default function PaymentSubmitRow<TData extends Payment, TParsedData extends Payment>({
  children,
  ...props
}: PaymentSubmitRowProps<TData, TParsedData>) {
  const {
    amount,
    currency,
    paymentCreate,
    paymentConfirm,
    parsePayment,
    validateFields,
    setResponseParams,
    result: paymentResult,
    ...rest
  } = props;

  const submitRef = React.useRef<HTMLDivElement>(null);
  const paymentRedirect = useFormieRedirectUrl();
  const router = useRouter();
  const pathname = usePathname();

  // devLog('paymentRedirect', paymentRedirect);

  const searchParams = useFormie((s) => s.searchParams);

  const formiePayment = useFormie((s) => s.payment) as FormiePaymentState;
  const paymentFieldHandle = formiePayment.fieldHandle;

  const formieIsSubmitting = useFormie((s) => s.isSubmitting);
  const formieIsSubmitted = useFormie((s) => s.isSubmitted);
  const formieIsSubmitError = useFormie((s) => s.isSubmitError);
  const formieSubmission = useFormie((s) => s.submission);

  const error = paymentCreate.error || paymentConfirm.error;

  const methods = useFormContext();
  const { triggerSubmit } = useFormieSubmit();

  // --------------------------------------------------------------------------
  // ---- UI state ----

  const [paymentErrorMessage, setErrorMessage] = React.useState<string | null>();
  // const [successMessage, setSuccessMessage] = React.useState<string | null>();
  // const [infoMessage, setInfoMessage] = React.useState<string | null>();

  // --------------------------------------------------------------------------
  // ---- Redirect ----

  const resetRedirect = () => {
    methods.reset();
    router.push(pathname, { scroll: false });
  };

  // --------------------------------------------------------------------------
  // ---- Status management ----

  const [status, setStatus] = React.useState<PaymentStatus>(PaymentStatus.IDLE);

  const isStatus = (...statuses: [TPaymentStatus, ...TPaymentStatus[]]) => {
    return statuses.includes(status);
  };

  // --------------------------------------------------------------------------
  // ---- Errors ----

  const clearPaymentError = () => {
    setErrorMessage(undefined);
    if (isStatus(PaymentStatus.PAYMENT_ERROR)) setStatus(PaymentStatus.IDLE);
  };

  const setPaymentError = (message?: string) => {
    setStatus(PaymentStatus.PAYMENT_ERROR);
    devError(message);
    setErrorMessage(message || 'There was an error processing your payment');
  };

  // --------------------------------------------------------------------------

  const clearPaymentValue = (reason: string) => {
    devLog('clearPaymentValue', reason);
    // setPayment(null);
    paymentFieldHandle && methods.setValue(paymentFieldHandle, '');
  };

  const [formIsValidated, setFormIsValidated] = React.useState(false);

  const canIgnoreErrors = React.useCallback(
    (errors: TFieldErrors | undefined | null = null) => {
      errors = errors === null ? methods.formState.errors : errors;

      if (!errors) return true;

      const invalidFields = keys(errors);

      return !invalidFields.length;
      // ||
      // (invalidFields.length === 1 && invalidFields[0] === paymentFieldHandle)
    },
    [methods.formState.errors, paymentFieldHandle],
  );

  const canPay = () => {
    if (!formieSubmission) {
      devError('No submission ID');
      return false;
    }

    if (formieSubmission.isSpam) {
      devError('Cannot pay for spam submission');
      return false;
    }

    return true;
  };

  const updatePaymentValue = (payment: TData | null, complete: boolean, reason: string) => {
    let paymentValue: TParsedData | null = null;

    if (payment && !(payment instanceof Error)) {
      paymentValue = parsePayment(payment, complete);

      if (!paymentValue) {
        devError('updatePaymentValue', 'No payment value');
        return;
      }

      if (!paymentValue.reference) {
        payment.reference = paymentValue.id;
      }
    }

    devLog('updatePaymentValue', paymentValue, reason);
    // setPayment(paymentValue);

    const paymentString = JSON.stringify(paymentValue);
    paymentFieldHandle && methods.setValue(paymentFieldHandle, paymentString);
  };

  // --------------------------------------------------------------------------
  // ---- Form validation ----

  const createIncompleteFormSubmission = async () => {
    /*
     * We expect an error when submitting the form with initial payment data
     */
    methods.clearErrors();
    methods.setValue('isIncomplete', true);
    triggerSubmit();
    setStatus(PaymentStatus.FORM_VALIDATING); // Set after triggerSubmit
  };

  // --------------------------------------------------------------------------
  // ---- Payment submission ----

  const handlePaymentSubmission = async (isPaymentComplete?: boolean) => {
    /*
     * All payment transactions should be complete
     * before the form is submitted
     */
    methods.setValue('isIncomplete', false);

    if (!canPay()) return setPaymentError('The payment cannot be submitted');

    clearPaymentError();

    if (isPaymentComplete) {
      triggerSubmit();
      setStatus(PaymentStatus.PAYMENT_FINAL_SUBMITTING); // Set after triggerSubmit
    } else {
      triggerSubmit();
      setStatus(PaymentStatus.PAYMENT_PARTIAL_SUBMITTING); // Set after triggerSubmit
    }
  };

  // --------------------------------------------------------------------------
  // ---- Payment creation ----

  const handlePaymentCreation = async () => {
    if (status === PaymentStatus.CREATING_PAYMENT) return;

    if (!formieSubmission || !canPay()) {
      return setPaymentError('The payment cannot be created');
    }

    setStatus(PaymentStatus.CREATING_PAYMENT);
    methods.clearErrors();
    setErrorMessage(undefined);

    // const metadata = methods.getValues();

    // delete metadata.isIncomplete;

    // metadata.submissionId = formieSubmission.id;
    // metadata.fieldId = formiePayment.fieldId;
    // metadata.formHandle = formHandle;

    const paymentResponse = await paymentCreate
      .trigger(
        {
          amount,
          currency,
        },
        // metadata,
      )
      .catch((err) => {
        devError(err);
        return;
      });

    if (!paymentResponse || paymentResponse instanceof Error) {
      clearPaymentValue('creation error');
      devError(paymentResponse);
      return setPaymentError('There was an error creating your payment');
    }

    updatePaymentValue(paymentResponse, false, 'payment created');
  };

  // --------------------------------------------------------------------------
  // ---- Payment confirmation ----

  const handlePaymentConfirmation = async () => {
    if (status === PaymentStatus.PAYMENT_CONFIRMING) return;

    if (!canPay()) {
      return setPaymentError('The payment cannot be completed');
    }

    setStatus(PaymentStatus.PAYMENT_CONFIRMING);
    setErrorMessage(undefined);

    const confirmationResponse = await paymentConfirm.trigger(paymentRedirect).catch((err) => {
      devError(err);
      return;
    });

    if (!confirmationResponse || confirmationResponse instanceof Error) {
      clearPaymentValue('confirmation error');
      devError(confirmationResponse);
      return setPaymentError('There was an error confirming your payment');
    }

    setStatus(PaymentStatus.PAYMENT_CONFIRMED);
  };

  // --------------------------------------------------------------------------
  // ---- Effects ----

  React.useEffect(() => {
    const hasSearchParams = !!searchParams && !!formieSubmission;

    if (!hasSearchParams) return;
    // PAYMENT_FINAL_SUBMITTING

    setStatus(PaymentStatus.CHECKING_PARAMS);
    // Provide the search params to the payment handler
    setResponseParams(searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const paymentNext = React.useCallback(async () => {
    /**
     * Next, check the current status
     */
    switch (status) {
      case PaymentStatus.CHECKING_PARAMS:
        /**
         * First, check if the payment result is available
         */
        if (paymentResult) {
          submitRef.current?.scrollIntoView({ behavior: 'smooth' });
          devLog('paymentResult', paymentResult);

          if (!paymentResult.success) {
            return setPaymentError(
              paymentResult.error || 'There was an error processing your payment',
            );
          }

          setStatus(PaymentStatus.PAYMENT_SUCCESS);
          updatePaymentValue(paymentResult.payment, true, 'payment success');
          return await handlePaymentSubmission(true); //! Update the submission
        }
        return;

      case PaymentStatus.IDLE: // Waiting for action
        return;

      case PaymentStatus.PAYMENT_ERROR: // Payment error - reset
        return;

      case PaymentStatus.FORM_VALIDATING: // Waiting for form validation
        setFormIsValidated(false); //! Shouldn't need this
        if (formieIsSubmitting) return;

        if (
          formieIsSubmitError &&
          !canIgnoreErrors() // Payment field should be invalid here
        ) {
          return setStatus(PaymentStatus.FORM_ERROR);
        }
        return setStatus(PaymentStatus.FORM_VALIDATED);

      case PaymentStatus.FORM_ERROR:
        if (formIsValidated) devError('Form has been validated');
        return;

      case PaymentStatus.FORM_VALIDATED: // Form has been validated
        setFormIsValidated(true); //! Shouldn't need this
        return await handlePaymentCreation();

      case PaymentStatus.CREATING_PAYMENT: // Waiting for payment to be created
        if (paymentCreate.isSuccess) {
          //! We should cache our own success state
          return setStatus(PaymentStatus.PAYMENT_CREATED);
        }
        return;

      case PaymentStatus.PAYMENT_CREATED: // Payment has been created
        return await handlePaymentSubmission(false); // Update the submission

      case PaymentStatus.PAYMENT_PARTIAL_SUBMITTING: // Submitting the payment
        if (formieIsSubmitting) return;
        if (formieIsSubmitError && !canIgnoreErrors()) {
          return setPaymentError('There was an error submitting your payment');
        }
        return setStatus(PaymentStatus.PAYMENT_PARTIAL_SUBMITTED);

      case PaymentStatus.PAYMENT_PARTIAL_SUBMITTED: // Partial payment has been submitted
        // return; //! Debugging
        return await handlePaymentConfirmation();

      case PaymentStatus.PAYMENT_CONFIRMING: // Completing the payment
        return;

      case PaymentStatus.PAYMENT_CONFIRMED: // Payment has been confirmed
        setStatus(PaymentStatus.PAYMENT_FINAL_SUBMITTING);
        return;

      case PaymentStatus.PAYMENT_FINAL_SUBMITTING: // Submitting the payment
        if (formieIsSubmitting) return;
        if (formieIsSubmitError /* && !canIgnoreErrors() */) {
          devError('Payment submission error');
          return setPaymentError('You payment was received but another error occurred');
          // return setInfoMessage(
          //   'You payment was received but another error occurred',
          // );
          // return setStatus(PaymentStatus.PAYMENT_SUCCESS); //! This is a hack for Formie payment issues.
        }
        return setStatus(PaymentStatus.PAYMENT_FINAL_SUBMITTED);

      case PaymentStatus.PAYMENT_FINAL_SUBMITTED: // Final payment has been submitted
        devLog('Final payment has been submitted');
        return setStatus(PaymentStatus.PAYMENT_SUCCESS);

      case PaymentStatus.PAYMENT_SUCCESS:
        devLog('Payment success');
        resetRedirect();
        submitRef.current?.scrollIntoView({ behavior: 'instant' });

        return;

      default:
        assertUnreachable(status); // Unknown status
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formieIsSubmitting,
    formieIsSubmitted,
    formieSubmission,
    formieIsSubmitError,
    status,
    paymentCreate.isPending,
    paymentCreate.isSuccess,
    paymentResult,
  ]);

  React.useEffect(() => {
    devLog('');
    devLog(' ---- ', PaymentStatus[status], ' ---- ');

    if (formieIsSubmitting) {
      devLog('...exiting... formieIsSubmitting');
      return;
    }
    paymentNext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, paymentNext]);

  /**
   * Handles the submission of the payment form.
   *
   * This function performs the following steps:
   * 1. Prevents submission if the application is in development mode (`IS_DEV`)
   *    and the form is already in the process of being submitted (`formieIsSubmitting`).
   * 2. Clears any existing error messages and resets the development logs.
   * 3. Validates the form fields and logs any validation errors.
   * 4. If the form is valid, initiates the creation of an incomplete form submission.
   *
   * @returns A promise that resolves to the result of `createIncompleteFormSubmission()`
   *          if the form is valid, or `undefined` if the form is invalid or submission is skipped.
   */
  const onSubmit = async () => {
    if (!IS_DEV && formieIsSubmitting) return;
    /*
     * We expect an error when submitting the form with initial payment data
     */
    setErrorMessage(undefined);

    devClear();
    devLog('handlePayment', PaymentStatus[status]);

    const isValid = await validateFields().catch((err) => {
      devError(err);
      return false;
    });

    if (!isValid) return;

    return createIncompleteFormSubmission();
  };

  // --------------------------------------------------------------------------
  // ---- Render props ----

  const formattedAmount = formatAmount(amount, currency, false);

  const showSuccess = isStatus(PaymentStatus.PAYMENT_SUCCESS);
  const showErrors = isStatus(PaymentStatus.PAYMENT_ERROR, PaymentStatus.FORM_ERROR);
  const showDefaultError = isStatus(PaymentStatus.FORM_ERROR);
  const errorMessage = showDefaultError ? undefined : paymentErrorMessage;
  // const showInfo = !!infoMessage;

  const isWorking = isStatus(
    PaymentStatus.FORM_VALIDATING,
    PaymentStatus.FORM_VALIDATED,
    // PaymentStatus.FORM_ERROR,
    PaymentStatus.CREATING_PAYMENT,
    PaymentStatus.PAYMENT_CREATED,
    PaymentStatus.PAYMENT_CONFIRMING,
    PaymentStatus.PAYMENT_CONFIRMED,
    PaymentStatus.PAYMENT_PARTIAL_SUBMITTING,
    PaymentStatus.PAYMENT_PARTIAL_SUBMITTED,
    PaymentStatus.PAYMENT_FINAL_SUBMITTING,
    PaymentStatus.PAYMENT_FINAL_SUBMITTED,
    PaymentStatus.PAYMENT_ERROR,
    PaymentStatus.PAYMENT_SUCCESS,
    PaymentStatus.CHECKING_PARAMS,
  );

  const submitDisabled = formieIsSubmitting || isWorking;

  const submitLabel = isStatus(
    PaymentStatus.PAYMENT_FINAL_SUBMITTED,
    PaymentStatus.PAYMENT_FINAL_SUBMITTING,
    PaymentStatus.PAYMENT_SUCCESS,
  )
    ? `Payment complete`
    : isWorking //formieIsSubmitting
      ? `Processing payment`
      : `Pay ${formattedAmount}`;

  const showPaymentFields = !isStatus(
    PaymentStatus.CHECKING_PARAMS,
    PaymentStatus.PAYMENT_SUCCESS,
    PaymentStatus.PAYMENT_FINAL_SUBMITTED,
    PaymentStatus.PAYMENT_FINAL_SUBMITTING,
  );

  // --------------------------------------------------------------------------

  return (
    <Stack ref={submitRef} spacing={2}>
      {IS_DEV && <pre>4242424242424242</pre>}
      {/* {paymentFieldHandle && (
        <FieldErrors errors={methods.getFieldState(paymentFieldHandle).error} />
      )}  */}
      {showPaymentFields && children}

      <FormieSubmitRow
        SubmitButtonProps={{
          ...rest,
          type: 'button',
          onClick: onSubmit,
          disabled: submitDisabled,
        }}
        submitLabel={submitLabel}
        error={!!error || !!paymentErrorMessage}
        errorMessage={errorMessage}
        // successMessage={successMessage}
        // infoMessage={infoMessage}
        hideSuccessMessage={!showSuccess}
        hideErrorMessage={!showErrors}
        // hideInfoMessage={!showInfo}
        forceSuccessMessage={showSuccess}
      />
    </Stack>
  );
}
