import { getFragmentData } from '@/__generated__';
import { TypedDocumentNode } from '@apollo/client';
import { PropsWithFragment, WithFragmentResult } from '@liquorice/gql-utils';
import { slugify } from '@liquorice/utils';

export interface BlockMeta {
  anchor?: string;
  anchorLabel?: string;
}

export type WithBlockCallback<TType, P extends BlockMeta, R> = (data: TType, props: P) => R;

/**
 * Extracts metadata from a block object.
 *
 * @param block - A record containing block properties.
 * @returns An object containing the block's metadata.
 *
 * @remarks
 * If the block contains a 'heading' property of type string, the returned metadata will include
 * the 'heading' value as the 'anchor'. Otherwise, the 'anchor' will be an empty string.
 */
export function getBlockMeta(block: Record<string, unknown>): BlockMeta | undefined {
  if ('heading' in block && typeof block.heading === 'string') {
    return {
      anchor: slugify(block.heading),
      anchorLabel: block.heading,
    };
  } else return undefined;
}

/**
 * Creates a block component that extracts fragment data and passes it to a callback function.
 *
 * @template TType - The type of the GraphQL fragment.
 * @template P - The type of the block meta data.
 * @template R - The return type of the callback function.
 *
 * @param {TypedDocumentNode<TType>} fragment - The GraphQL fragment to extract data from.
 * @param {WithFragmentCallback<TType, P, R>} callback - The callback function to be called with the fragment data and block meta data.
 *
 * @returns {WithFragmentResult<TType, P, R>} - A React component that extracts fragment data and passes it to the callback function.
 */
export default function createBlock<TType, P extends BlockMeta, R>(
  fragment: TypedDocumentNode<TType>,
  callback: WithBlockCallback<TType, P, R>,
) {
  function BlockComponent({ data, ...props }: PropsWithFragment<TType, P>) {
    const fragmentData = getFragmentData(fragment, data);
    if (!fragmentData) return null;

    const blockMeta = getBlockMeta(fragmentData);
    return callback(fragmentData, { ...blockMeta, ...props } as P);
  }

  return BlockComponent as WithFragmentResult<TType, P, R>;
}
