'use client';

import { FormieFormFragment } from '@/__generated__/graphql';
import React from 'react';
import ErrorFallback from '../components/ErrorFallback';
import FormieForm from '../components/FormieForm';
import FormieProvider from '../components/FormieProvider';
import LoadingFallback from '../components/LoadingFallback';
import { RecaptchaProvider } from '../components/Recaptcha';
import { FormValues } from '../lib/formTypes';
import useFormieInitialState from '../lib/useFormieInitialState';

export interface FormComponentOptions {
  /**
   * Disables the Recaptcha provider.
   * Use this option if you want to use your own Recaptcha provider.
   */
  disableRecaptchaProvider?: boolean;
  /**
   * Disables the Recaptcha badge.
   *
   * Disabling the badge will output Google's terms at the bottom of the form.
   */
  hideRecaptchaBadge?: boolean;
  /**
   * Fallback component to show while the form is loading.
   */
  loadingFallback?: React.ReactNode;
  /**
   * Fallback component to show if the form fails to load.
   */
  errorFallback?: React.ReactNode;
  /**
   *
   */
  defaultValues?: FormValues;
}

export interface FormDataProps {
  form?: FormieFormFragment | null;
  loading?: boolean;
  error?: boolean | Error;
}

export interface FormProps extends FormDataProps, FormComponentOptions {}

export default function Form(props: FormProps) {
  const {
    form,
    loading,
    error,
    loadingFallback,
    errorFallback,
    disableRecaptchaProvider,
    hideRecaptchaBadge,
  } = props;

  const initialState = useFormieInitialState(props);

  if (loading || initialState.isLoading) {
    return <>{loadingFallback ?? <LoadingFallback />}</>;
  }

  if (error || !form || initialState.isError) {
    return <>{errorFallback ?? <ErrorFallback />}</>;
  }

  const { formieState } = initialState;

  const formOutput = (
    <FormieProvider initialState={formieState}>
      <FormieForm />
    </FormieProvider>
  );

  return disableRecaptchaProvider ? (
    formOutput
  ) : (
    <RecaptchaProvider
      reCaptchaKey={formieState.gRecaptchaSettings?.gRecaptchaSiteKey}
      hideBadge={hideRecaptchaBadge}>
      {formOutput}
    </RecaptchaProvider>
  );
}
