import { gql } from '@/__generated__';

export const FORMIE_FORM_EXISTS_QUERY = gql(`
  query formieFormExists($uid: [String]!) {
    formieForm(uid: $uid) {
      id
      handle
      submissionMutationName
    }
  }
`);

export const FORMIE_INCOMPLETE_SUBMISSION_EXISTS_QUERY = gql(`
  query formieIncompleteSubmissionExists($uid: [String]!, $formUid: [String]!) {
    formieSubmission(uid: $uid, form: $formUid, isIncomplete: true, isSpam: false) {
      uid
    }
  }
`);
