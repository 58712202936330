'use client';

import { gql } from '@/__generated__';
import { withFragmentArray } from '@liquorice/gql-utils';
import { FormMethodsProps } from '../../lib/formTypes';
import FormieField from '../FormieField';
// import FormieRowContainer from '../FormieRowContainer';
import React from 'react';

const FORMIE_ROW_FRAGMENT = gql(`
  fragment formieRow on RowInterface {
    __typename
    id
    rowFields {
      ...field
    }
  }
`);

export type FormieFieldRowProps = React.PropsWithChildren<
  {
    parentHandle?: string;
  } & FormMethodsProps
>;

const FormieRowContainer = React.lazy(() => import('../FormieRowContainer'));

const FormieRows = withFragmentArray(
  FORMIE_ROW_FRAGMENT,
  (rows, { methods, parentHandle }: FormieFieldRowProps) => {
    if (!rows) return null;

    return (
      <>
        {rows.map((row, rowIndex) => {
          if (!row || !row.rowFields?.length) return null;

          return (
            <FormieRowContainer key={row.id ?? rowIndex} marginBottom={4}>
              {row.rowFields.map((field, i) => (
                <FormieField key={i} data={field} methods={methods} parentHandle={parentHandle} />
              ))}
            </FormieRowContainer>
          );
        })}
      </>
    );
  },
);

export default FormieRows;
