import { PaymentElement } from '@stripe/react-stripe-js';
import PaymentSubmitRow from '../../components/PaymentSubmitRow';
import type { AmountSettings, HandlesPayment } from '../../types';
import useStripePayment from './useStripePayment';

export interface FormieStripeFieldsProps
  extends AmountSettings,
    HandlesPayment {}

const FormieStripeFields = (props: FormieStripeFieldsProps) => {
  const stripeMethods = useStripePayment(props);

  const { amount, currency } = props;

  return (
    <PaymentSubmitRow amount={amount} currency={currency} {...stripeMethods}>
      <PaymentElement />
    </PaymentSubmitRow>
  );
};

export default FormieStripeFields;
